import type React from "react";
import { useState, useEffect, useMemo } from "react";
import {
  Typography,
  TextField,
  Box,
  Autocomplete,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import PheonixButton from "../Components/PheonixButton";
import { PheonixBox } from "pheonixui";
import PheonixTextField from "../Components/PheonixTextField";
import { TEXT_MESSAGES } from "../const";
import { useTheme } from "@mui/material/styles";
import {
  GET_ALL_CUSTOMER,
  ADD_QUOTATION_MUTATION,
  EDIT_QUOTATION_MUTATION,
  GET_ALL_PRODUCTS,
  GET_LAST_QUOTATION,
} from "../graphql/query";
import { useQuery } from "@apollo/client";
import QuotationModel from "../Components/QuotationModel";
import baseUrl from "../api";
import axios from "axios";

interface Customer {
  id: string;
  name: string;
}
interface AddQuotationProps {
  onClose: () => void;
  onSave: (data: any) => void;
  quotationData?: any;
  viewMode?: boolean;
  editMode?: boolean;
  stockId?: string;
}
interface Product {
  id: number;
  product: string;
  description: string;
  hsnCode: string;
  quantity: string;
  unit: string;
  rate: number;
  taxRate: string | number;
  sales_gst: number;
  sales_price: number;
  [key: string]: string | number;
}
const AddQuotation: React.FC<AddQuotationProps> = ({
  onClose,
  onSave,
  quotationData,
  editMode = false,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const [currency, setCurrency] = useState<string | null>("");
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const [quotationStatus, setQuotationStatus] = useState<string | null>();
  const [quotationDate, setQuotationDate] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [expiryDate, setExpiryDate] = useState<string>("");
  const [quotationNotes, setQuotationNotes] = useState<string>("");
  const [quotationDialog, setQuotationDialog] = useState(false);
  const [quotationCode, setQuotationCode] = useState("");
  const [autoGenerate, setAutoGenerate] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [customerNames, setCustomerNames] = useState<Customer[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [termsandconditions, settermsandconditions] = useState<string>("");
  const [customerNotes, setcustomerNotes] = useState<string>("");
  const [products, setProducts] = useState<Product[]>([
    {
      id: 1,
      product: "",
      description: "",
      hsnCode: "",
      quantity: "",
      unit: "",
      rate: 0,
      taxRate: 0,
      sales_gst: 0,
      sales_price: 0,
    },
  ]);
  const [productErrors, setProductErrors] = useState<string[]>([]);
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [cgst, setCgst] = useState<number>(0);
  const [sgst, setSgst] = useState<number>(0);
  const [totalAmountWithoutTaxes, setTotalAmountWithoutTaxes] =
    useState<number>(0);
  const [roundoff, setRoundoff] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [error, setError] = useState({
    customer: false,
    currency: false,
    quotationDate: false,
    expiryDate: false,
  });
  const commonStyles = {
    textField: {
      width: "100%",
      maxWidth: "300px",
      height: "48px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root": {
        color: "gray",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        backgroundColor: "#FFFFFF",
      },

      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "10px",
        fontFamily: "Lexend",
        margin: 0,
        padding: 0,
      },
    },
    autoComplete: {
      width: "100%",
      maxWidth: "348px",
      "& .MuiAutocomplete-root": {
        color: "#00000061",
      },
    },
  };
  const currencies = ["INR", "USD", "EURO"];
  const statuses = ["Active", "Inactive", "Accepted", "Rejected"];
  const units = ["Bags", "Pieces", "Kgs"];

  const currencySymbols: { [key: string]: string } = {
    INR: "₹",
    USD: "$",
    EURO: "€",
  };
  const exchangeRates: { [key: string]: number } = {
    INR: 1,
    USD: 0.012,
    EURO: 0.011,
  };
  const convertRate = (rate: number, from: string, to: string) => {
    return (rate / exchangeRates[from]) * exchangeRates[to];
  };
  const handleRoundoffChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseFloat(event.target.value) || 0; 
    setRoundoff(value);
    calculateTotals(products, value);
  };

  const handleCurrencyChange = (newCurrency: string | null) => {
    if (!newCurrency) return;

    const oldCurrency = currency || "INR";

    setCurrency(newCurrency);
    setCurrencySymbol(currencySymbols[newCurrency]);

    if (newCurrency !== "INR") {
      setCgst(0);
      setSgst(0);
    } else {
      const taxRate =
        products.length > 0
          ? (typeof products[0].taxRate === "string"
              ? Number.parseFloat(products[0].taxRate.replace("%", ""))
              : products[0].taxRate) || 0
          : 0;

      setCgst(taxRate / 2);
      setSgst(taxRate / 2);
    }

    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        const convertedRate = convertRate(
          product.rate,
          oldCurrency,
          newCurrency
        );

        const quantity = Number(product.quantity) || 0;
        const newSalesPrice = quantity * convertedRate;
        return {
          ...product,
          rate: convertedRate,
          sales_price: newSalesPrice,
        };
      })
    );

    setTimeout(() => calculateTotals(products, roundoff), 0);
  };
  const filteredProducts = customerNames.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const { data, loading } = useQuery(GET_ALL_CUSTOMER);
  const { data: productData, loading: productloading } =
    useQuery(GET_ALL_PRODUCTS);
  const { data: quotation, loading: quotationLoading } =
    useQuery(GET_LAST_QUOTATION);
  useEffect(() => {
    if (data?.getAllCustomers?.customers) {
      const customerList = data.getAllCustomers.customers.map(
        (customers: any) => ({
          id: customers.id,
          name: customers.customer_name,
        })
      );
      setCustomerNames(customerList);
    }
  }, [data]);
  useEffect(() => {
    if (currency && currencySymbols[currency]) {
      setCurrencySymbol(currencySymbols[currency]);
    }
  }, [currency]);

  useEffect(() => {
    if (productData?.getAllProducts?.products) {
      const productList = productData?.getAllProducts?.products.map(
        (product: any) => ({
          id: product.id,
          product: product.product_name,
          description: product.product_description,
          hsnCode: product.hsn_code,
          quantity: "",
          quantityactual: product.quantity,
          unit: product.unit,
          rate: product.sales_rate,
          taxRate: product.sales_gst,
          sales_price: 0,
          date: product.date,
        })
      );
      setAvailableProducts(productList);
    }
  }, [productData]);

  useEffect(() => {
    if (quotationData && customerNames.length > 0) {
      const matchedCustomer =
        customerNames.find((p) => p.name === quotationData.customer_name) ||
        null;
      setSelectedCustomer(matchedCustomer);
      setCurrency(quotationData.currency?.toString() || "");
      setQuotationCode(quotationData.quotation_code);
      setQuotationStatus(quotationData.status);
      setQuotationNotes(quotationData.quotation_notes || "");
      setQuotationDate(
        quotationData.date ? formatDate(quotationData.date) : ""
      );
      setExpiryDate(
        quotationData.expiry_date ? formatDate(quotationData.expiry_date) : ""
      );
      settermsandconditions(quotationData.termsandconditions);
      setcustomerNotes(quotationData.customerNotes);
      setCgst(quotationData.cgst);
      setSgst(quotationData.sgst);
      setTotalAmountWithoutTaxes(quotationData.totalAmountWithoutTaxes);
      setGrandTotal(quotationData.grandTotal);

      if (quotationData.productdata) {
        const mappedProducts = quotationData.productdata.map((product: any, index: number) => ({
          id: index + 1,
          product: product.product_name,
          description: product.product_description,
          hsnCode: product.hsn_code.toString(),
          quantity: product.quantity?.toString() || "",
          unit: product.unit,
          rate: product.sales_rate,
          taxRate: product.sales_price_gst,
          sales_gst: product.sales_price_gst,
          sales_price: product.sales_price,
        }));
        setProducts(mappedProducts);
      }
    }
  }, [quotationData, customerNames]);
  const formatDate = (date: string): string => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleProductSelect = (
    index: number,
    field: string,
    selectedProduct: Product | null,
    value: any
  ) => {
    if (!selectedProduct) {
      setProductErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "Please select a product.";
        return newErrors;
      });
      return;
    }

    setProductErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = "";
      return newErrors;
    });

    const isDuplicate = products.some(
      (product, idx) =>
        idx !== index && product.product === selectedProduct.product
    );

    if (isDuplicate) {
      setProductErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "This product is already added to the table.";
        return newErrors;
      });
      return;
    }

    let taxRate = 0;

    if (typeof selectedProduct.taxRate === "string") {
      taxRate =
        Number.parseFloat(selectedProduct.taxRate.replace("%", "")) || 0;
    } else if (typeof selectedProduct.taxRate === "number") {
      taxRate = selectedProduct.taxRate;
    }

    const convertedRate =
      currency !== "INR"
        ? convertRate(selectedProduct.rate, "INR", currency || "INR")
        : selectedProduct.rate;

    const newProducts = [...products];
    newProducts[index] = {
      ...newProducts[index],
      product: selectedProduct.product,
      description: String(selectedProduct.description),
      hsnCode: selectedProduct.hsnCode,
      unit: selectedProduct.unit,
      rate: convertedRate,
      taxRate: taxRate,
      sales_gst: selectedProduct.sales_gst,
    };

    if (newProducts[index].quantity) {
      const quantity = Number(newProducts[index].quantity) || 0;
      newProducts[index].sales_price = quantity * convertedRate;
    }

    if (field === "quantity") {
      const quantity = Number(value) || 0;
      const actualQuantity = Number(newProducts[index].quantityactual) || 0;

      if (quantity > actualQuantity) {
        setProductErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          newErrors[
            index
          ] = `Quantity cannot exceed available stock (${actualQuantity})`;
          return newErrors;
        });
      } else {
        newProducts[index].sales_price = quantity * newProducts[index].rate;
      }
    }

    setProducts(newProducts);

    if (currency === "INR") {
      setCgst(taxRate / 2);
      setSgst(taxRate / 2);
    } else {
      setCgst(0);
      setSgst(0);
    }

    calculateTotals(newProducts, roundoff);
  };

  const calculateTotals = (productList: Product[], roundOff: number) => {
    const total = productList.reduce((sum, p) => sum + (p.sales_price || 0), 0);
    setTotalAmountWithoutTaxes(total);

    let taxRate = 0;
    if (productList.length > 0 && productList[0].taxRate) {
      if (typeof productList[0].taxRate === "string") {
        taxRate =
          Number.parseFloat(productList[0].taxRate.replace("%", "")) || 5;
      } else if (typeof productList[0].taxRate === "number") {
        taxRate = productList[0].taxRate;
      }
    }
    const taxAmount = currency === "INR" ? (total * taxRate) / 100 : 0;
    const grandTotal = Math.round(total - roundOff + taxAmount);
    setGrandTotal(grandTotal);

    if (currency === "INR") {
      const cgst = taxRate / 2;
      const sgst = taxRate / 2;
      setCgst(cgst);
      setSgst(sgst);
    } else {
      setCgst(0);
      setSgst(0);
    }
  };
  useEffect(() => {
    calculateTotals(products, roundoff);
  }, [roundoff, products]);

  const handleProductChange = (
    index: number,
    field: keyof Product,
    value: number | string | null
  ) => {
    const newProducts = [...products];
    newProducts[index][field] = value as string | number;
    if (field === "quantity" || field === "rate") {
      const quantity = Number(newProducts[index].quantity) || 0;
      const rate = Number(newProducts[index].rate) || 0;
      newProducts[index].sales_price = quantity * rate;
    }
    setProducts(newProducts);
    calculateTotals(newProducts, roundoff);
  };
  const handleAddProduct = () => {
    setProducts([
      ...products,
      {
        id: products.length + 1,
        product: "",
        description: "",
        hsnCode: "",
        quantity: "",
        unit: "",
        rate: 0,
        taxRate: 0,
        sales_gst: 5,
        sales_price: 0,
      },
    ]);
  };
  const handleRemoveProduct = (index: number) => {
    if (products.length === 1) {
      setProducts([
        {
          id: 1,
          product: "",
          description: "",
          hsnCode: "",
          quantity: "",
          unit: "",
          rate: 0,
          taxRate: 0,
          sales_gst: 5,
          sales_price: 0,
        },
      ]);
      setTotalAmountWithoutTaxes(0);
      setGrandTotal(0);
      return;
    }
    const newProducts = products.filter((_, i) => i !== index);
    setProducts(newProducts);
    calculateTotals(newProducts, roundoff);
  };
  const handleQuotationConfirm = (finalQuotationCode: string) => {
    setQuotationCode(finalQuotationCode);
    setQuotationDialog(false);
    handleSave(finalQuotationCode);
  };
  const handleOpenDialog = (mode: "add" | "edit") => {
    setIsEditMode(mode === "edit");
    setQuotationCode(mode === "edit" ? quotationCode : "");
    setQuotationDialog(true);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const handleSave = async (finalQuotationCode: string) => {
    let hasError = false;
    const newErrors = {
      customer: !selectedCustomer,
      currency: !currency,
      quotationDate: !quotationDate,
      expiryDate: !expiryDate,
    };
    setError(newErrors);
    hasError = Object.values(newErrors).some((error) => error);
    if (hasError) return;

    const variables = {
      id: quotationData ? quotationData.id : null,
      quotation_code: finalQuotationCode,
      customer_name: selectedCustomer?.name,
      currency,
      status: quotationStatus,
      date: quotationDate,
      expiry_date: expiryDate,
      quotation_notes: quotationNotes,
      termsandconditions: termsandconditions,
      customernotes: customerNotes,
      amountwithouttax: totalAmountWithoutTaxes,
      cgst: cgst,
      sgst: sgst,
      grandtotal: grandTotal,
      productdata: products.map((product) => ({
        product_name: product.product,
        product_description: product.description,
        hsn_code: Number.parseInt(product.hsnCode, 10),
        quantity: Number.parseInt(product.quantity, 10),
        unit: product.unit,
        sales_rate: product.rate,
        sales_gst: product.taxRate,
        sales_price: product.sales_price,
      })),
    };

    try {
      let response;
      if (editMode && quotationData) {
        response = await axios.post(baseUrl, {
          query: EDIT_QUOTATION_MUTATION.loc?.source.body,
          variables,
        });
        const editQuotation = response.data.data?.editQuotation;
        if (editQuotation?.statusCode === 200) {
          onSave(editQuotation.quotation);
          onClose();
        } else {
          setErrorMessage(
            editQuotation?.message || "Failed to update quotation"
          );
        }
      } else {
        response = await axios.post(baseUrl, {
          query: ADD_QUOTATION_MUTATION.loc?.source.body,
          variables,
        });
        const { addQuotation } = response.data.data;

        if (addQuotation?.statusCode === 201) {
          onSave(addQuotation.quotation);
          resetForm();
          onClose();
        } else {
          if (addQuotation?.message?.includes("E11000 duplicate key error")) {
            setErrorMessage(
              "Quotation Code already exists. Please enter a different one."
            );
          } else {
            setErrorMessage(addQuotation?.message || "Unknown error");
          }
        }
      }
    } catch (error: any) {
      console.error("Network error:", error.message || error);
      setErrorMessage("A network error occurred. Please try again.");
    }
  };

  const sortedProducts = useMemo(() => {
    const sorted = [...availableProducts].sort((a: Product, b: Product) => {
      const idAString = String(a.id).padStart(24, "0");
      const idBString = String(b.id).padStart(24, "0");
      const timeA = parseInt(idAString.slice(0, 8), 16);
      const timeB = parseInt(idBString.slice(0, 8), 16);
      return timeB - timeA;
    });
    return sorted;
  }, [availableProducts]);

  const resetForm = () => {
    setCustomerNames([]);
    setCurrency("");
    setQuotationDate("");
    setExpiryDate("");
    setQuotationNotes("");
    setQuotationStatus("");
    settermsandconditions("");
    setcustomerNotes("");
    setProducts([]);
    setCgst(0);
    setSgst(0);
    setTotalAmountWithoutTaxes(0);
    setRoundoff(0);
    setGrandTotal(0);
  };

  return (
    <PheonixBox
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        paddingX: { xs: "8px", sm: "10px", md: "16px" },
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        border: "1px solid",
        borderColor: "rgba(224,224,224,1)",
        height: { xs: "auto", sm: "73vh" },
        position: "relative",
        boxSizing: "border-box",
        overflowY: { xs: "visible", sm: "auto" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "12px", sm: "16px", md: "128px" },
          width: "100%",
          padding: { xs: "8px", sm: "16px" },
        }}
      >
        <Autocomplete
          options={customerNames.filter((customer) =>
            customer.name.toLowerCase().includes(searchTerm.toLowerCase())
          )}
          getOptionLabel={(option) => option.name}
          value={selectedCustomer}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(_, newValue) => {
            setSelectedCustomer(newValue);
            setError((prev) => ({ ...prev, customer: !newValue }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              error={error.customer}
              helperText={error.customer ? TEXT_MESSAGES.CUSTOMER_REQ : ""}
              label="Select Customer"
              variant="standard"
              sx={commonStyles.autoComplete}
            />
          )}
          sx={commonStyles.textField}
        />
        <Autocomplete
          options={currencies}
          value={currency}
          onChange={(_, newValue) => {
            handleCurrencyChange(newValue);
            setError((prev) => ({ ...prev, currency: !newValue }));
          }}
          sx={commonStyles.textField}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              error={error.currency}
              helperText={error.currency ? TEXT_MESSAGES.CURRENCY_REQ : ""}
              label="Select Currency"
              variant="standard"
              sx={commonStyles.autoComplete}
            />
          )}
        />
        <Autocomplete
          options={statuses}
          value={quotationStatus}
          onChange={(_, newValue) => setQuotationStatus(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Quotation Status"
              variant="standard"
              sx={commonStyles.autoComplete}
            />
          )}
          sx={commonStyles.textField}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "12px", sm: "16px", md: "128px" },
          width: "100%",
          padding: { xs: "8px", sm: "16px" },
        }}
      >
        <PheonixTextField
          label="Quotation Date"
          type="date"
          value={quotationDate}
          onChange={(e) => {
            setQuotationDate(e.target.value);
            setError((prev) => ({ ...prev, quotationDate: !e.target.value }));
          }}
          variant="standard"
          required
          error={error.quotationDate}
          helperText={
            error.quotationDate ? TEXT_MESSAGES.QUOTATIONDATE_REQ : ""
          }
          InputProps={{
            inputProps: {
              placeholder: quotationDate ? " " : " quotationDate",
              max: new Date().toISOString().split("T")[0],
            },
          }}
          sx={{
            width: isSmallScreen ? "100%" : "348px",
            maxWidth: "300px",
            height: "48px",
            "& .MuiInputBase-root input::-webkit-datetime-edit": {
              opacity: quotationDate ? 1 : 0,
            },
            "& .MuiInputBase-root": {
              backgroundColor: "transparent",
            },
            "& .MuiInputLabel-root": {
              color: "gray",
              fontFamily: "Lexend",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black",
              backgroundColor: "#FFFFFF",
              fontFamily: "Lexend",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "gray",
            },
            "& .MuiFormLabel-asterisk": {
              color: "red",
            },
            "& .MuiFormHelperText-root": {
              fontSize: "10px",
              fontFamily: "Lexend",
              margin: 0,
              Padding: 0,
            },
          }}
        />
        <PheonixTextField
          label="Expiry Date"
          type="date"
          value={expiryDate}
          onChange={(e) => {
            setExpiryDate(e.target.value);
            setError((prev) => ({ ...prev, expiryDate: !e.target.value }));
          }}
          variant="standard"
          required
          error={error.expiryDate}
          helperText={
            error.expiryDate ? TEXT_MESSAGES.QUOTATIONEXPIRY_DATE : ""
          }
          InputProps={{
            inputProps: {
              placeholder: expiryDate ? " " : " expiryDate",
              min: new Date().toISOString().split("T")[0],
            },
          }}
          sx={{
            width: isSmallScreen ? "100%" : "300px",
            maxWidth: "300px",
            height: "48px",
            "& .MuiInputBase-root input::-webkit-datetime-edit": {
              opacity: expiryDate ? 1 : 0,
            },
            "& .MuiInputBase-root": {
              backgroundColor: "transparent",
            },
            "& .MuiInputLabel-root": {
              color: "gray",
              fontFamily: "Lexend",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black",
              backgroundColor: "#FFFFFF",
              fontFamily: "Lexend",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "gray",
            },
            "& .MuiFormLabel-asterisk": {
              color: "red",
            },
            "& .MuiFormHelperText-root": {
              fontSize: "10px",
              fontFamily: "Lexend",
              margin: 0,
              Padding: 0,
            },
          }}
        />
        <PheonixTextField
          type="text"
          label="Quotation Notes"
          multiline
          rows={1}
          value={quotationNotes}
          onChange={(e) => setQuotationNotes(e.target.value)}
          variant="standard"
          sx={commonStyles.textField}
        />
      </Box>
      <Box sx={{ width: "100%", display: "block", marginTop: "10px" }}>
        <Box
          sx={{
            minWidth: {
              xs: "100%",
              sm: "800px",
              md: "1000px",
              lg: "1200px",
            },
            maxHeight: { xs: "auto", sm: "150px" },
            overflowX: "auto",
            overflowY: { xs: "visible", sm: "auto" },
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                {[
                  TEXT_MESSAGES.SNO,
                  TEXT_MESSAGES.SELECT_PRODUCT,
                  TEXT_MESSAGES.PRODUCT_DESCRIPTION,
                  TEXT_MESSAGES.HSN_CODE,
                  TEXT_MESSAGES.QUANTITY,
                  TEXT_MESSAGES.UNIT,
                  TEXT_MESSAGES.RATE_PER_ITEM,
                  TEXT_MESSAGES.TAXES,
                  TEXT_MESSAGES.AMOUNT,
                  TEXT_MESSAGES.ACTIONS,
                ].map((header, idx) => (
                  <th
                    key={idx}
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                      whiteSpace: "nowrap",
                      minWidth:
                        idx === 0 || idx === 9
                          ? isSmallScreen
                            ? "60px"
                            : "80px"
                          : isSmallScreen
                          ? "100px"
                          : "130px",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f5f5f5",
                      zIndex: 2,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    }}
                  >
                    {header}{" "}
                    {idx !== 0 && <span style={{ color: "red" }}>*</span>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={index}>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                      textAlign: "center",
                      fontSize: isSmallScreen ? "12px" : "14px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <Autocomplete
                      options={sortedProducts}
                      getOptionLabel={(option) => option.product}
                      value={
                        product.product
                          ? sortedProducts.find(
                              (p) => p.product === product.product
                            ) || null
                          : null
                      }
                      onChange={(_, newValue) =>
                        handleProductSelect(index, "product", newValue, null)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          variant="standard"
                          placeholder="Select product"
                          error={!!productErrors[index]}
                          helperText={productErrors[index] || ""}
                          sx={{ ...commonStyles.textField, width: "100%" }}
                        />
                      )}
                      sx={{ ...commonStyles.autoComplete, width: "100%" }}
                    />
                  </td>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <PheonixTextField
                      required
                      type="text"
                      value={product.description}
                      onChange={(e) =>
                        handleProductChange(
                          index,
                          "product_description",
                          e.target.value
                        )
                      }
                      variant="standard"
                      sx={{ ...commonStyles.textField, width: "100%" }}
                    />
                  </td>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <PheonixTextField
                      required
                      type="text"
                      value={product.hsnCode}
                      onChange={(e) =>
                        handleProductChange(index, "hsn_code", e.target.value)
                      }
                      variant="standard"
                      sx={{ ...commonStyles.textField, width: "100%" }}
                    />
                  </td>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <PheonixTextField
                      type="number"
                      required
                      value={product.quantity}
                      onChange={(e) =>
                        handleProductChange(
                          index,
                          "quantity",
                          Number(e.target.value)
                        )
                      }
                      variant="standard"
                      error={!!productErrors[index]}
                      helperText={productErrors[index] || ""}
                      sx={{ ...commonStyles.textField, width: "100%" }}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </td>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <Autocomplete
                      options={units}
                      value={product.unit}
                      onChange={(_, newValue) =>
                        handleProductChange(index, "unit", newValue)
                      }
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard" />
                      )}
                      sx={{ width: { xs: "100%", sm: "100px" } }}
                    />
                  </td>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <PheonixTextField
                      type="text"
                      required
                      value={String(product.rate)}
                      onChange={(e) =>
                        handleProductChange(
                          index,
                          "sales_rate",
                          Number(e.target.value)
                        )
                      }
                      variant="standard"
                      sx={{ ...commonStyles.textField, width: "100%" }}
                    />
                  </td>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <PheonixTextField
                      type="text"
                      required
                      value={`${product.taxRate}%`}
                      onChange={(e) =>
                        handleProductChange(
                          index,
                          "taxRate",
                          Number(e.target.value.replace("%", ""))
                        )
                      }
                      variant="standard"
                      sx={{ ...commonStyles.textField, width: "100%" }}
                    />
                  </td>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                      whiteSpace: "nowrap",
                      fontSize: isSmallScreen ? "12px" : "14px",
                    }}
                  >
                    {currencySymbol}
                    {product.sales_price}
                  </td>
                  <td
                    style={{
                      padding: isSmallScreen ? "4px" : "8px",
                      border: "1px solid #ddd",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton onClick={handleAddProduct} color="success">
                      <AddCircleOutlineOutlinedIcon
                        fontSize={isSmallScreen ? "small" : "medium"}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleRemoveProduct(index)}
                      color="error"
                    >
                      <DeleteIcon
                        fontSize={isSmallScreen ? "small" : "medium"}
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          padding: { xs: "8px", sm: "16px" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: "12px", sm: "16px" },
        }}
      >
        <Box
          sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "5px" }}
        >
          <Typography sx={{ color: "#00000061" }}>
            {TEXT_MESSAGES.CUSTOMER_NOTESLABEL}
          </Typography>
          <textarea
            value={customerNotes}
            onChange={(e) => setcustomerNotes(e.target.value)}
            style={{
              border: "0.5px solid",
              borderRadius: "10px",
              width: isSmallScreen ? "90%" : "464px",
              height: "68px",
              fontFamily: "Lexend",
              fontWeight: "400",
              fontSize: isSmallScreen ? "14px" : "16px",
              padding: "8px",
              boxSizing: "border-box",
            }}
          ></textarea>
          <Typography sx={{ color: "#00000061" }}>
            {TEXT_MESSAGES.TERMS_AND_CONDITION}
          </Typography>
          <textarea
            value={termsandconditions}
            onChange={(e) => settermsandconditions(e.target.value)}
            style={{
              border: "0.5px solid",
              borderRadius: "10px",
              width: isSmallScreen ? "90%" : "464px",
              height: "68px",
              fontFamily: "Lexend",
              fontWeight: "400",
              fontSize: isSmallScreen ? "14px" : "16px",
              padding: "8px",
              boxSizing: "border-box",
            }}
          ></textarea>
        </Box>
        <Box
          sx={{
            flex: { xs: 1, md: 2 },
            width: "100%",
            maxWidth: { xs: "90%", sm: "368px" },
            marginRight: { xs: "0", sm: "10px" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <table
            style={{
              width: isSmallScreen ? "100%" : "50%",
              borderCollapse: "collapse",
              marginLeft: isSmallScreen ? "0" : "auto",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#f5f5f5" }}>
                <th
                  style={{
                    padding: isSmallScreen ? "4px" : "8px",
                    border: "1px solid #ddd",
                    borderLeft: "none",
                    borderRight: "none",
                    fontSize: isSmallScreen ? "12px" : "14px",
                  }}
                >
                  {TEXT_MESSAGES.CGST}
                </th>
                <th
                  style={{
                    padding: isSmallScreen ? "4px" : "8px",
                    border: "1px solid #ddd",
                    borderLeft: "none",
                    borderRight: "none",
                    fontSize: isSmallScreen ? "12px" : "14px",
                  }}
                >
                  {TEXT_MESSAGES.SGST}
                </th>
                <th
                  style={{
                    padding: isSmallScreen ? "4px" : "8px",
                    border: "1px solid #ddd",
                    borderLeft: "none",
                    borderRight: "none",
                    fontSize: isSmallScreen ? "12px" : "14px",
                  }}
                >
                  {TEXT_MESSAGES.AMOUNT}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: isSmallScreen ? "4px" : "8px",
                    border: "none",
                  }}
                >
                  <TextField
                    value={cgst !== null ? `${cgst}%` : ""}
                    onChange={(e) => {
                      let value = e.target.value.replace("%", "");
                      const newCgst = Number(value);

                      if (!isNaN(newCgst)) {
                        setCgst(newCgst);
                        setSgst(newCgst);
                        const newTaxRate = newCgst * 2;
                        products.forEach((p, idx) =>
                          handleProductChange(idx, "taxRate", newTaxRate)
                        );
                      }
                    }}
                    variant="standard"
                    sx={{ width: "100px" }}
                  />
                </td>
                <td
                  style={{
                    padding: isSmallScreen ? "4px" : "8px",
                    border: "none",
                  }}
                >
                  <TextField
                    value={sgst !== null ? `${sgst}%` : ""}
                    onChange={(e) => {
                      let value = e.target.value.replace("%", "");
                      const newSgst = Number(value);

                      if (!isNaN(newSgst)) {
                        setCgst(newSgst);
                        setSgst(newSgst);
                        const newTaxRate = newSgst * 2;
                        products.forEach((p, idx) =>
                          handleProductChange(idx, "taxRate", newTaxRate)
                        );
                      }
                    }}
                    variant="standard"
                    sx={{ width: isSmallScreen ? "100%" : "100px" }}
                  />
                </td>
                <td
                  style={{
                    padding: isSmallScreen ? "4px" : "8px",
                    border: "none",
                  }}
                >
                  <TextField
                    value={`${grandTotal - totalAmountWithoutTaxes}`}
                    variant="standard"
                    onChange={(e) =>
                      setTotalAmountWithoutTaxes(Number(e.target.value))
                    }
                    sx={{ width: isSmallScreen ? "100%" : "100px" }}
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Box
            sx={{
              width: "100%",
              marginTop: { xs: "12px", sm: "20px" },
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography
              style={{
                fontWeight: "700px",
                fontFamily: "Lexend",
                fontSize: "14px",
              }}
            >
              {TEXT_MESSAGES.TOTAL_AMOUNT}
              {currencySymbol}
              {totalAmountWithoutTaxes}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                style={{
                  fontWeight: "700px",
                  fontFamily: "Lexend",
                  fontSize: "14px",
                }}
              >
                {TEXT_MESSAGES.ROUND_OFF}
              </Typography>

              <PheonixTextField
                type="text"
                value={String(roundoff)}
                onChange={handleRoundoffChange}
                variant="standard"
                sx={{
                  marginBottom: "5px",
                }}
              />
            </Box>

            <Typography
              style={{
                fontWeight: "700px",
                fontFamily: "Lexend",
                fontSize: "14px",
              }}
            >
              {TEXT_MESSAGES.TOTAL} {currencySymbol}
              {grandTotal}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: { xs: "static", sm: "absolute" },
          bottom: { sm: 0 },
          paddingX: { xs: "8px", sm: "16px" },
          paddingY: "16px",
          width: { xs: "100%", sm: "90%" },
          borderTop: { xs: "none", sm: "1px solid rgba(224, 224, 224, 1)" },
          display: "flex",
          justifyContent: "flex-end",
          gap: "5px",
          flexWrap: "wrap",
        }}
      >
        <PheonixButton
          label={TEXT_MESSAGES.CANCEL}
          variant="outlined"
          width={isSmallScreen ? "80px" : "90px"}
          sx={{
            transform: "none",
            textTransform: "none",
            "&:hover": { backgroundColor: "black" },
            fontSize: isSmallScreen ? "12px" : "14px",
          }}
          onClick={onClose}
        />
        <PheonixButton
          label={TEXT_MESSAGES.SAVE}
          width={isSmallScreen ? "80px" : "90px"}
          backgroundColor="black"
          color="primary"
          variant="contained"
          onClick={() => setQuotationDialog(true)}
          sx={{
            transform: "none",
            textTransform: "none",
            "&:hover": { backgroundColor: "black" },
            fontSize: isSmallScreen ? "12px" : "14px",
          }}
        />
      </Box>
      <QuotationModel
        open={quotationDialog}
        onClose={() => setQuotationDialog(false)}
        onConfirm={handleQuotationConfirm}
        isEditMode={false}
      />
    </PheonixBox>
  );
};
export default AddQuotation;
