import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Switch,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixTextField from "../Components/PheonixTextField";
import { TEXT_MESSAGES } from "../const";
import { Padding } from "@mui/icons-material";

interface ProductDetailsProps {
  data: Record<string, any>;
  onSave: (data: Record<string, any>) => void;
  error?: string | null;
  readOnly?: boolean;
  checkAll?: boolean;
  fieldErrors: Record<string, string>;
  setFieldErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  data,
  onSave,
  error,
  readOnly,
  checkAll,
  fieldErrors,
  setFieldErrors,
}) => {
  const [isTaxable, setIsTaxable] = useState(true);
  const [gst, setGST] = useState<string | null>(null);
  const [unit, setUnit] = useState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [formData, setFormData] = useState(data);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    if (formData.isTaxable) {
      setTouched((prev) => ({
        ...prev,
        gst_percentage: true,
        gst_type: true,
      }));
    }
  }, []);

  const validateField = (field: string, value: any) => {
    let error = "";
    if (
      [
        "product_name",
        "quantity",
        "product_description",
        "unit",
        "gst_percentage",
        "expirydate",
        "entrydate",
      ].includes(field)
    ) {
      if (!value) {
        error = `${field.replace("_", " ")} is required`;
      }
    }
    return error;
  };

  const handleInputChange = (field: string, value: any) => {
    let parsedValue = value;
    if (field === "gst_percentage" && !value.endsWith("%")) {
      parsedValue = `${value}%`;
    } else if (["quantity", "hsn_code"].includes(field)) {
      parsedValue = value === "" ? "" : parseInt(value, 10);
      if (isNaN(parsedValue)) parsedValue = "";
    }

    const updatedData = { ...formData, [field]: parsedValue };
    setFormData(updatedData);

    const error = validateField(field, parsedValue);
    setFieldErrors((prev) => ({ ...prev, [field]: error }));

    onSave(updatedData);
  };

  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const error = validateField(field, formData[field]);
    setFieldErrors((prev) => ({ ...prev, [field]: error }));
  };

  const handleSave = () => {
    let errors: { [key: string]: string } = {};
    Object.keys(formData).forEach((field) => {
      errors[field] = validateField(field, formData[field]);
    });

    setFieldErrors(errors);
    setTouched(
      Object.keys(formData).reduce(
        (acc, field) => ({ ...acc, [field]: true }),
        {}
      )
    );

    if (Object.values(errors).every((error) => error === "")) {
      onSave(formData);
    }
  };

  const formatDate = (date: string): string => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const commonStyles = {
    textField: {
      width: "100%",
      maxWidth: "348px",
      height: "48px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root": {
        color: "gray",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "gray",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "10px",
        fontFamily: "Lexend",
        margin: 0,
        Padding: 0,
      },
    },
    autoComplete: {
      width: "100%",
      maxWidth: "348px",
      "& .MuiAutocomplete-root": {
        color: "#00000061",
      },
    },
  };

  const gstType = [
    { value: "Taxable", label: "Taxable" },
    { value: "Non-Taxable", label: "Non-Taxable" },
    { value: "Out of scope", label: "Out of scope" },
    { value: "Non-GST supply", label: "Non-GST supply" },
  ];
  const gstOptions = [
    { value: "0%", label: "0%" },
    { value: "5%", label: "5%" },
    { value: "12%", label: "12%" },
    { value: "18%", label: "18%" },
    { value: "28%", label: "28%" },
  ];

  const unitOptions = [
    { value: "Bags", label: "Bags" },
    { value: "Kilogram", label: "Kilogram" },
    { value: "Gram", label: "Gram" },
    { value: "Centimeter", label: "Centimeter" },
  ];

  const handleSwitchChange = () => {
    setIsTaxable(!isTaxable);
  };

  const handleGSTpercentage = (_: React.SyntheticEvent, newValue: any) => {
    setGST(newValue?.value || "");
  };
  const handleGSTtype = (_: React.SyntheticEvent, newValue: any) => {
    setGST(newValue?.value || "");
    setIsTaxable(newValue?.value === "Taxable");
  };

  const handleUnitChange = (_: React.SyntheticEvent, newValue: any) => {
    setUnit(newValue?.value || "");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen
          ? "column"
          : isMediumScreen
          ? "column"
          : "row",
        gap: isSmallScreen ? "16px" : "24px",
        padding: isSmallScreen ? "16px" : "24px",
        height: "auto",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "17px",
          alignItems: isSmallScreen ? "stretch" : "flex-start",
        }}
      >
        <PheonixTextField
          type="text"
          label="Product Name"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.product_name || ""}
          error={Boolean(fieldErrors.product_name)}
          helperText={fieldErrors.product_name}
          onBlur={() =>
            setFieldErrors((prev) => ({
              ...prev,
              product_name: validateField(
                "product_name",
                formData.product_name
              ),
            }))
          }
          onChange={(e) => {
            handleInputChange("product_name", e.target.value);
            handleBlur("product_name");
          }}
          sx={commonStyles.textField}
        />
        <PheonixTextField
          label="Quantity"
          type="text"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.quantity}
          error={Boolean(fieldErrors.quantity)}
          helperText={fieldErrors.quantity}
          onBlur={() =>
            setFieldErrors((prev) => ({
              ...prev,
              quantity: validateField("quantity", formData.quantity),
            }))
          }
          onChange={(e) => {
            handleBlur("quantity");
            handleInputChange("quantity", e.target.value);
          }}
          sx={commonStyles.textField}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            width: isSmallScreen ? "100%" : "348px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                fontSize: "14px",
                whiteSpace: "nowrap",
                fontFamily: "Lexend",
              }}
            >
              {TEXT_MESSAGES.GST_TYPE}
            </Typography>
            <Switch
              checked={readOnly ? true : formData.isTaxable}
              disabled={readOnly}
              onChange={() =>
                handleInputChange("isTaxable", !formData.isTaxable)
              }
              size="small"
              sx={{
                width: "40px",
                padding: "0",
                "& .MuiSwitch-thumb": {
                  width: "16px",
                  height: "16px",
                  backgroundColor: "black",
                  boxShadow: "none",
                },
                "& .MuiSwitch-switchBase": {
                  padding: "2px",
                  "&.Mui-checked": {
                    transform: "translateX(20px)",
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "black",
                    },
                  },
                },
                "& .MuiSwitch-track": {
                  borderRadius: "10px",
                  backgroundColor: "gray",

                  width: "26px",
                  height: "10px",
                  margin: "5px",
                  transition: "background-color 0.2s",
                  "&.Mui-checked": {
                    backgroundColor: "black",
                  },
                },
              }}
            />
          </Box>
          {(readOnly || formData.isTaxable) && (
            <Autocomplete
              options={gstType}
              disabled={readOnly}
              getOptionLabel={(option) => option.label}
              value={
                gstType.find((option) => option.value === formData.gst_type) ||
                null
              }
              onChange={(_, newValue) => {
                handleBlur("gst_type");
                handleInputChange("gst_type", newValue?.value || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={formData.isTaxable}
                  disabled={readOnly}
                  label=""
                  variant="standard"
                  error={
                    formData.isTaxable && touched.gst_type && !formData.gst_type
                  }
                  helperText={
                    formData.isTaxable && touched.gst_type && !formData.gst_type
                      ? "GST Type is required"
                      : ""
                  }
                  onBlur={() => handleBlur("gst_type")}
                  sx={{
                    ...commonStyles.textField,
                    fontFamily: "Lexend",
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} style={{ fontFamily: "Lexend" }}>
                  {option.label}
                </li>
              )}
              sx={{
                visibility:
                  readOnly || formData.isTaxable ? "visible" : "hidden",
                width: isSmallScreen ? "100%" : "348px",
                fontFamily: "Lexend",
              }}
            />
          )}
        </Box>

        <PheonixTextField
          label="Entry date"
          type="date"
          disabled={readOnly}
          variant="standard"
          value={formData.entrydate ? formatDate(formData.entrydate) : ""}
          error={Boolean(fieldErrors.entrydate)}
          helperText={fieldErrors.entrydate}
          onBlur={() =>
            setFieldErrors((prev) => ({
              ...prev,
              entrydate: validateField("entrydate", formData.entrydate),
            }))
          }
          onChange={(e) => handleInputChange("entrydate", e.target.value)}
          InputProps={{
            inputProps: {
              placeholder: formData.entrydate ? " " : " Entry date",
              max: new Date().toISOString().split("T")[0],
            },
          }}
          required
          sx={{
            width: isSmallScreen ? "100%" : "348px",
            height: "48px",
            "& .MuiInputBase-root input::-webkit-datetime-edit": {
              opacity: formData.entrydate ? 1 : 0,
            },
            "& .MuiInputBase-root": {},
            "& .MuiInputLabel-root": {
              color: "gray",
              fontFamily: "Lexend",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black",
              backgroundColor: "#FFFFFF",
              fontFamily: "Lexend",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "gray",
            },
            "& .MuiFormLabel-asterisk": {
              color: "red",
            },
            "& .MuiFormHelperText-root": {
              fontSize: "10px",
              fontFamily: "Lexend",
              margin: 0,
              Padding: 0,
            },
          }}
        />

        <PheonixTextField
          label="HSN Code"
          type="text"
          disabled={readOnly}
          variant="standard"
          value={formData.hsn_code}
          onChange={(e) => handleInputChange("hsn_code", e.target.value)}
          sx={commonStyles.textField}
        />
      </Box>

      <Box
        sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "17px" }}
      >
        <PheonixTextField
          type="text"
          id="outlined-error"
          label="Product Description"
          disabled={readOnly}
          variant="standard"
          required
          value={formData.product_description}
          error={Boolean(fieldErrors.product_description)}
          helperText={fieldErrors.product_description}
          onBlur={() =>
            setFieldErrors((prev) => ({
              ...prev,
              product_description: validateField(
                "product_description",
                formData.product_description
              ),
            }))
          }
          onChange={(e) =>
            handleInputChange("product_description", e.target.value)
          }
          sx={commonStyles.textField}
        />

        <Autocomplete
          options={unitOptions}
          disabled={readOnly}
          getOptionLabel={(option) => option.label}
          value={
            unitOptions.find((option) => option.value === formData.unit) || null
          }
          onChange={(_, newValue) => {
            handleBlur("unit");
            handleInputChange("unit", newValue?.value || "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={readOnly}
              label="Unit"
              required
              value={formData.unit}
              error={Boolean(fieldErrors.unit)}
              helperText={fieldErrors.unit}
              onBlur={() =>
                setFieldErrors((prev) => ({
                  ...prev,
                  unit: validateField("unit", formData.unit),
                }))
              }
              variant="standard"
              sx={{ ...commonStyles.textField, fontFamily: "Lexend" }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ fontFamily: "Lexend" }}>
              {option.label}
            </li>
          )}
          sx={{ ...commonStyles.autoComplete, fontFamily: "Lexend" }}
        />

        {(readOnly || formData.isTaxable) && (
          <Autocomplete
            options={gstOptions}
            disabled={readOnly}
            getOptionLabel={(option) => option.label}
            value={
              gstOptions.find(
                (option) => option.value === formData.gst_percentage
              ) || null
            }
            onChange={(_, newValue) => {
              handleBlur("gst_percentage");
              handleInputChange("gst_percentage", newValue?.value || "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={formData.isTaxable}
                disabled={readOnly}
                label="GST Percentage"
                variant="standard"
                error={
                  formData.isTaxable &&
                  touched.gst_percentage &&
                  !formData.gst_percentage
                }
                helperText={
                  formData.isTaxable &&
                  touched.gst_percentage &&
                  !formData.gst_percentage
                    ? "GST Percentage is required"
                    : ""
                }
                onBlur={() => handleBlur("gst_percentage")}
                sx={{
                  ...commonStyles.textField,
                  fontFamily: "Lexend",
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} style={{ fontFamily: "Lexend" }}>
                {option.label}
              </li>
            )}
            sx={{
              visibility: readOnly || formData.isTaxable ? "visible" : "hidden",
              width: isSmallScreen ? "100%" : "348px",
              fontFamily: "Lexend",
            }}
          />
        )}
        <PheonixTextField
          label="Expiry date"
          type="date"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.expirydate ? formatDate(formData.expirydate) : ""}
          error={Boolean(fieldErrors.expirydate)}
          helperText={fieldErrors.expirydate}
          onBlur={() =>
            setFieldErrors((prev) => ({
              ...prev,
              expirydate: validateField("expirydate", formData.expirydate),
            }))
          }
          onChange={(e) => handleInputChange("expirydate", e.target.value)}
          InputProps={{
            inputProps: {
              placeholder: formData.expirydate ? " " : " Expiry date",
              min: new Date().toISOString().split("T")[0],
            },
          }}
          sx={{
            width: isSmallScreen ? "100%" : "348px",
            height: "48px",
            "& .MuiInputBase-root input::-webkit-datetime-edit": {
              opacity: formData.expirydate ? 1 : 0,
            },
            "& .MuiInputBase-root": {
              backgroundColor: "transparent",
            },
            "& .MuiInputLabel-root": {
              color: "gray",
              fontFamily: "Lexend",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black",
              backgroundColor: "#FFFFFF",
              fontFamily: "Lexend",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "gray",
            },
            "& .MuiFormLabel-asterisk": {
              color: "red",
            },
            "& .MuiFormHelperText-root": {
              fontSize: "10px",
              fontFamily: "Lexend",
              margin: 0,
              Padding: 0,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ProductDetails;
