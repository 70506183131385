import React, { useState, useEffect } from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PheonixButton from "../Components/PheonixButton";
import { TEXT_MESSAGES, pdfdata } from "../const";
import { useQuery } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import themestyle from "../theme";
import EditIcon1 from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PheonixPaper from "../Components/PheonixPaper";
import ModalFormData from "../interfaces/ModalFormDataProps";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import { PheonixBox, PheonixSnackbar } from "pheonixui";
import { useLocation } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import product from "../assets/product.png";
import { GET_QUOTATION_QUERY, DELETE_QUOTATION } from "../graphql/query";
import PheonixPopup from "../Components/PheonixPopup";
import EditConfirmationDialog from "./Editmodel";
import AddQuotation from "./Addquotation";
import QuotationView from "./ViewQuotation";
import IosShareIcon from "@mui/icons-material/IosShare";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DeleteConfirmationDialog from "./Deletemodel";
import DeleteModal from "../Components/PheonixDeletemodel";
import axios from "axios";
import baseUrl from "../api";
import { useMutation } from "@apollo/client";
import { RESEND_EMAIL_MUTATION } from "../graphql/query";
import logo from "../assets/logo.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from "@mui/material";
import { ReactNode } from "react";

interface ProductData {
  product_name: string;
  product_description?: string;
  quantity: number;
  unit: string;
  gst_percentage?: number | null;
  hsn_code: number;
  sales_price: number;
  sales_rate: number;
  sales_price_gst?: number | null;
}

interface Quotation {
  id: string;
  quotation_code: string;
  customer_name: string;
  currency: string;
  status: string;
  date: string;
  expiry_date: string;
  quotation_notes: string;
  serial_no?: string | null;
  productdata?: ProductData[] | null;
}
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

const Quotation: React.FC = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [dataLoading, setDataLoading] = useState(false);
  const [tableData, setTableData] = useState<ModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showQuotation, setShowQuotation] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<
    "add" | "edit" | "delete" | ""
  >("");
  const [snackbarTypeColor, setSnackbarTypeColor] = useState<
    "add" | "edit" | "delete" | ""
  >("");
  const [alertMessage, setAlertMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  const [currentView, setCurrentView] = useState<string>(
    location.state?.currentView || "list"
  );
  const [breadcrumbText, setBreadcrumbText] = useState<string>(
    location.state?.breadcrumbText || "Quotation Listing"
  );
  const [popup, setPopup] = useState(false);
  const [selectedQuotation, setSelectedQuotation] =
    useState<ModalFormData | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [resendEmailQuotationMutation] = useMutation(RESEND_EMAIL_MUTATION);

  const handleAddQuotation = () => {
    setCurrentView("addquotations");
    setBreadcrumbText("Add Quotations");
    setShowQuotation(true);
  };

  const handleOpenDialog = (quotation: ModalFormData) => {
    setSelectedQuotation(quotation);
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedQuotation(null);
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: 0, 
    }));
    if (newQuery.trim() === "") {
      quotationRefetch({ variables: { searchQuery: {} } });
    } else {
      quotationRefetch();
    }
  };
  const handleBreadcrumbClick = (breadcrumbText: string) => {
    if (breadcrumbText === "quotation" && currentView !== "list") {
      setPopup(true);
    }
  };
  const handlePopupClose = () => {
    setActiveStep(0);
    setCurrentView("list");
    setShowQuotation(false);
    setBreadcrumbText("Quotation Listing");
    setPopup(false);
  };
  const handleSnackbarOpen = (type: "add" | "edit" | "delete") => {
    setSnackbarType(type);
    setSnackbarTypeColor(type);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
      setSnackbarType("");
      setSnackbarTypeColor("");
    }, 3000);
  };
  const handleCancelEdit = () => {
    setActiveStep(0);
    setIsEditing(false);
    setCurrentView("list");
    setBreadcrumbText("Quotation Listing");
    setSelectedQuotation(null);
  };
  const handleEditClick = (quotation: ModalFormData) => {
    setSelectedQuotation(quotation);
    setBreadcrumbText("Edit Quotation");
    setCurrentView("edit");
    setIsEditing(true);
  };
  const handleEditConfirmation = () => {
    setIsEditDialogOpen(false);
    setBreadcrumbText("Edit Quotation");
    setCurrentView("edit");
    setIsEditing(true);
  };
  const handleViewClick = (quotation: ModalFormData) => {
    setSelectedQuotation(quotation);
    setIsView(true);
    setBreadcrumbText("View Quotation");
    setCurrentView("view");
  };
  const handleSaveEdit = () => {
    setBreadcrumbText("Quotation Listing");
    setCurrentView("list");
    const editedQuotationCode = selectedQuotation?.quotation_code || "Unknown";
    setSelectedQuotation(null);
    quotationRefetch();
    const successMessage = TEXT_MESSAGES.EDITED_QUOTATION.replace("{code}", editedQuotationCode);
    setAlertMessage(successMessage);
    handleSnackbarOpen("edit");
  };
  const handleSave = (newQuotation: ModalFormData) => {
    const newQuotationWithId = {
      ...newQuotation,
      id: newQuotation.id || `temp-id-${Math.random()}`,
    };
    setTableData((prev) => [...prev, newQuotationWithId]);
    setShowQuotation(false);
    setCurrentView("list");
    setBreadcrumbText("Quotation Listing");
    quotationRefetch();
    const successMessage = TEXT_MESSAGES.ADDING_QUOTATION.replace("{code}", newQuotation.quotation_code);
  setAlertMessage(successMessage);
    handleSnackbarOpen("add");
    quotationRefetch();
  };

  const handleEdit = () => {
    setIsEditDialogOpen(true);
  };
  const handleEditquotation = () => {
    setIsEditing(true);
  };

  const handleDelete = async (quotation: ModalFormData): Promise<void> => {
    try {
        const response = await axios.post(baseUrl, {
            query: DELETE_QUOTATION.loc?.source.body,
            variables: { id: quotation.id },
        });
        const { statusCode, message } = response.data.data.deleteQuotation;

        if (statusCode === 200) {
            setTableData((prev) => prev.filter((p) => p.id !== quotation.id));
            const successMessage = TEXT_MESSAGES.QUOTATION_DELETE.replace("{code}", quotation.quotation_code);
            setAlertMessage(successMessage); 
            handleSnackbarOpen("delete");
            quotationRefetch();
        } else {
            alert(message || "Failed to delete quotation.");
        }
    } catch (error: any) {
        alert(`Error: ${error.message}`);
    }
};

  const handlePaginationChange = (newModel: {
    page: number;
    pageSize: number;
  }) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: newModel.page,
      pageSize: newModel.pageSize,
    }));
  };
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: quotationRefetch,
  } = useQuery(GET_QUOTATION_QUERY, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery,
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, quotations } = data.getQuotationsPerPage;

      const startIndex = paginationModel.page * paginationModel.pageSize + 1;

      const quotationWithSerialNo = quotations.map(
        (quotation: ModalFormData, index: number) => ({
          ...quotation,
          id: quotation.id || `${paginationModel.page}-${startIndex + index}`,
          serial_no: startIndex + index + 1,
          amount: quotation.productdata?.reduce(
            (total, product) => total + (product.sales_price || 0),
            0
          ),
          productdata: quotation.productdata || [],
        })
      );
      setTableData(quotationWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setDataLoading(false);
      quotationRefetch();
    },
  });

   useEffect(() => {
      setActiveStep(0); 
      quotationRefetch({
        variables: {
          page: paginationModel.page,
          perPage: paginationModel.pageSize,
          searchQuery: searchQuery.trim() === "" ? undefined : searchQuery,
        },
      });
    }, [searchQuery, paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    setDataLoading(true);
    if (
      !customerLoading &&
      !customerError &&
      customerData?.getQuotationsperPage
    ) {
      const { totalPages, totalCount, quotations } =
        customerData.getQuotationsPerPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;

      const quotationsWithSerialNo = quotations.map(
        (quotation: ModalFormData, index: number) => ({
          ...quotation,
          id: quotation.id || `${paginationModel.page}-${startIndex + index}`,

          serial_no: startIndex + index + 1,
        })
      );
      setTableData(quotationsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    }
    setDataLoading(false);
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);
  useEffect(() => {
    setActiveStep(0);
    quotationRefetch({
      variables: {
        page: paginationModel.page,
        perPage: paginationModel.pageSize,
        searchQuery: searchQuery.trim() === "" ? undefined : searchQuery,
      },
    });
  }, [searchQuery, paginationModel.page, paginationModel.pageSize]);

  const getCurrencySymbol = (currency: any) => {
    switch (currency) {
      case "INR":
        return "Rs.";
      case "USD":
        return "$";
      case "EURO":
        return "€";
    }
  };
  const handleDownloadPDF = (quotation: any) => {
    const jspdf = require("jspdf");
    const autotable = require("jspdf-autotable");
    const doc = new jspdf.default();
    doc.addImage(logo, "JPEG", 35, 50, 50, 35);
    const currencydata = quotation.currency;
    const currencysymbl = getCurrencySymbol(currencydata);

    doc.setFontSize(24);
    doc.setFont("helvetica", "bold");
    doc.text("Quotation", 15, 20);
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Quotation ID : #${quotation.quotation_code}`, 15, 35);
    doc.text(
      `Quotation Date : ${new Date(quotation.date).toLocaleDateString()}`,
      15,
      42
    );
    doc.text(
      `Expiry Date : ${new Date(quotation.expiry_date).toLocaleDateString()}`,
      15,
      49
    );
    doc.setTextColor("#808080");
    doc.text("From ", 15, 60);
    doc.setTextColor("#000000");
    const pheonixAddress = pdfdata.From;
    doc.text(pheonixAddress, 12, 65);
    const products =
      quotation.productdata?.map((prod: any) => [
        prod.product_name,
        prod.quantity,
        prod.unit,
        prod.gst_percentage ? `${prod.gst_percentage}%` : "N/A",
        `${currencysymbl} ${prod.sales_price}`,
      ]) || [];

    const subtotal =
      quotation.productdata?.reduce(
        (sum: any, prod: any) => sum + prod.sales_price,
        0
      ) || 0;
    const gst = (subtotal * 2.5) / 100;
    const total = subtotal + gst * 2;

    let startY = 110;

    doc.autoTable({
      startY,
      head: [["Product", "Quantity", "Unit", "Tax", "Price"]],
      body: products,
      theme: "grid",
    });

    const finalY = (doc as any).lastAutoTable.finalY + 10;
    doc.autoTable({
      startY: finalY,
      body: [
        ["Subtotal", `${currencysymbl} ${subtotal.toFixed(2)}`],
        ["CGST 2.5%", `${gst.toFixed(2)}`],
        ["SGST 2.5%", `${gst.toFixed(2)}`],
        [
          { content: "Total", styles: { fontStyle: "bold" } },
          `${currencysymbl} ${total.toFixed(2)}`,
        ],
      ],
      theme: "plain",
      styles: { fontSize: 12, cellPadding: 3 },
      columnStyles: {
        0: { halign: "right" },
        1: { halign: "left" },
      },
    });
    doc.save(`Quotation_${quotation.quotation_code}.pdf`);
  };

  const handleShareEmail = async (quotation: any) => {
    const userId = localStorage.getItem("userId");
    const productData =
      quotation.productdata?.map((prod: any) => ({
        id: prod.id,
        product_name: prod.product_name,
        quantity: prod.quantity,
        unit: prod.unit,
        sales_price: prod.sales_price,
      })) || [];
    const subtotal =
      quotation.productdata?.reduce(
        (sum: any, prod: any) => sum + prod.sales_price,
        0
      ) || 0;
    const gst = (subtotal * 2.5) / 100;
    const total = subtotal + gst * 2;
    const variables = {
      _id: quotation.id,
      user_id: userId,
      customer_name: quotation.customer_name,
      date: new Date(quotation.date),
      expiry_date: new Date(quotation.expiry_date),
      quotation_code: quotation.quotation_code,
      status: quotation.status,
      productdata: productData,
      price: String(total),
      currency: quotation.currency,
      taxamount: String(gst * 2),
    };
    try {
      const { data } = await resendEmailQuotationMutation({ variables });
      const { resendEmail } = data;
      if (resendEmail && resendEmail.success) {
        setAlertMessage(
          `${quotation.quotation_code} - Quotation ${TEXT_MESSAGES.QUOTE_SHARE_SUCCESS}`
        );
        handleSnackbarOpen("add");
      } else {
        setAlertMessage(TEXT_MESSAGES.QUOTE_SHARE_FAILED);
        handleSnackbarOpen("delete");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "Date",
      width: 200,
      renderCell: (params: any) => {
        if (!params.row?.date) return "No Date";

        const date =
          params.row.date instanceof Date
            ? params.row.date
            : new Date(params.row.date);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
    },
    {
      field: "quotation_code",
      headerName: "Quotation Number",
      flex: 1,
      minWidth: 60,
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      flex: 1,
      minWidth: 60,
      renderCell: (params: any) => {
        if (!params.row?.expiry_date) return "No Date";

        const date =
          params.row.expiry_date instanceof Date
            ? params.row.expiry_date
            : new Date(params.row.expiry_date);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      minWidth: 60,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 50,
      renderCell: (params): ReactNode => {
        const status = params.row?.status?.toLowerCase().trim() || "unknown";
        return (
          <Tooltip title={status.charAt(0).toUpperCase() + status.slice(1)}>
            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {status === "active" && <CheckCircleIcon style={{ color: "#67C6AA" }} />}
              {status === "inactive" && <InfoIcon style={{ color: "gray" }} />}
              {status === "accepted" && <CheckCircleIcon style={{ color: "#61C454" }} />}
              {status === "rejected" && <CancelIcon style={{ color: "red" }} />}
              <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
            </span>
          </Tooltip>
        );
      },      
    },    
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 50,
      renderCell: (params): ReactNode => {
        const currency = params.row?.currency || "INR"; 
        const amount = params.row?.amount?.toLocaleString() || "0"; 
        return (
          <span>
            {getCurrencySymbol(currency)} {amount}
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-8px",
          }}
        >
          <IconButton onClick={() => handleViewClick(params.row)}>
            <RemoveRedEyeSharpIcon
              style={{
                width: "20px",
                height: "20px",
                color: "black",
                marginTop: "7px",
              }}
            />
          </IconButton>
          <IconButton onClick={() => handleOpenDialog(params.row)}>
            <DeleteIcon
              style={{
                width: "20px",
                height: "20px",
                color: "black",
                marginTop: "7px",
              }}
            />
          </IconButton>
          <DeleteConfirmationDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            productName={selectedQuotation?.quotation_code || ""}
            onConfirm={handleDelete}
            selectedProduct={selectedQuotation}
            entityName="Information"
          />
        </div>
      ),
      align: "center",
    },
  ];
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: isMobileSmall ? "15px 25px" : "15px 30px",
            marginLeft: "-12px",
            fontFamily: "Lexend",
          }}
        >
          <Typography
            style={{
              fontFamily: "Lexend",
              color: themestyle.colors.black,
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
            }}
          >
            {TEXT_MESSAGES.QUOTATION}
          </Typography>
          <PheonixBreadcrumbs
            breadcrumbText={breadcrumbText}
            onBreadcrumbClick={handleBreadcrumbClick}
          />
        </div>
        {currentView === "view" && selectedQuotation && (
          <PheonixBox>
            <PheonixButton
              label={isSmallScreen ? "" : TEXT_MESSAGES.CONVERT_INVOICE}
              width={isSmallScreen ? "43px" : "165px"}
              sx={{
                background: themestyle.colors.Primary2,
                color: themestyle.colors.white,
                minWidth: isSmallScreen ? "30px" : "100px",
                marginRight: "20px",
                marginTop: "10px",
                transform: "none",
                textTransform: "none",
              }}
            />
            <PheonixButton
              label={isSmallScreen ? "" : TEXT_MESSAGES.DOWNLOAD}
              width={isSmallScreen ? "43px" : "152px"}
              startIcon={<PictureAsPdfOutlinedIcon />}
              sx={{
                background: themestyle.colors.Primary2,
                color: themestyle.colors.white,
                minWidth: isSmallScreen ? "30px" : "90px",
                marginRight: "20px",
                marginTop: "10px",
                transform: "none",
                textTransform: "none",
              }}
              onClick={() => handleDownloadPDF(selectedQuotation)}
            />
            <PheonixButton
              label={isSmallScreen ? "" : TEXT_MESSAGES.SHARE}
              width={isSmallScreen ? "43px" : "97px"}
              startIcon={<IosShareIcon />}
              sx={{
                background: themestyle.colors.Primary2,
                color: themestyle.colors.white,
                minWidth: isSmallScreen ? "30px" : "90px",
                marginRight: "20px",
                marginTop: "10px",
                transform: "none",
                textTransform: "none",
              }}
              onClick={() => handleShareEmail(selectedQuotation)}
            />
            <PheonixButton
              label={isSmallScreen ? "" : TEXT_MESSAGES.EDIT_BUTTON}
              width={isSmallScreen ? "43px" : "90px"}
              startIcon={<EditIcon1 />}
              sx={{
                background: themestyle.colors.Primary2,
                color: themestyle.colors.white,
                minWidth: isSmallScreen ? "30px" : "90px",
                marginRight: "20px",
                marginTop: "10px",
                transform: "none",
                textTransform: "none",
              }}
              onClick={handleEdit}
            />
          </PheonixBox>
        )}
        {currentView === "list" && tableData.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: isSmallScreen
                  ? "85px"
                  : isMediumScreen
                  ? "340px"
                  : "401px",
                height: isSmallScreen ? "32px" : "43px",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                flexWrap: "nowrap",
                gap: isSmallScreen ? "13px" : isMediumScreen ? "15px" : "32px",
                padding: isMediumScreen ? "16px" : "0",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <PheonixButton
                label={
                  isSmallScreen ? (
                    <ListAltIcon style={{ width: "24px", height: "24px" }} />
                  ) : (
                    TEXT_MESSAGES.ADD_QUOTATION
                  )
                }
                style={{
                  background: themestyle.colors.Primary2,
                  color: themestyle.colors.white,
                  width: isSmallScreen ? "auto" : "150px",
                  minWidth: isSmallScreen ? "40px" : "150px",
                  height: "43px",
                  fontWeight: 500,
                  fontSize: "16px",
                  transform: "none",
                  textTransform: "none",
                  padding: isSmallScreen ? "5px" : "10px",
                  marginRight: "10px",
                }}
                onClick={handleAddQuotation}
              />
            </div>
          </div>
        )}
      </div>
      <Box sx={{ padding: "15px" }}>
        {showQuotation ? (
          <AddQuotation
            onClose={() => {
              setShowQuotation(false);
              setCurrentView("list");
              setBreadcrumbText("Quotation Listing");
            }}
            onSave={handleSave}
          />
        ) : currentView === "edit" && selectedQuotation ? (
          <AddQuotation
            onSave={handleSaveEdit}
            onClose={handleCancelEdit}
            quotationData={selectedQuotation}
            editMode={true}
          />
        ) : currentView === "view" && selectedQuotation ? (
          <QuotationView
            onClose={() => {
              setIsView(false);
              setCurrentView("list");
              setBreadcrumbText("Quotation Listing");
            }}
            onSave={handleSaveEdit}
            quotation={selectedQuotation}
          />
        ) : (
          <PheonixPaper>
            {tableData.length === 0 ? (
              <div style={{ width: "80%", position: "fixed" }}>
                <PheonixDataGrid
                  columns={[]}
                  rows={[]}
                  searchQuery={searchQuery}
                  onSearchQueryChange={handleSearchQueryChange}
                  rowCount={tableData.length}
                  showPagination={tableData.length > 0}
                  sx={{
                    "& .MuiDataGrid-footerContainer": { visibility: "hidden" },
                    "& .MuiButtonBase-root.MuiButton-root": {
                      color: "#49955E",
                    },
                  }}
                  customEmptyContent={
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          mb: 2,
                          textAlign: isSmallScreen ? "center" : "left",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: isSmallScreen ? "14px" : "16px",
                          }}
                        >
                          {TEXT_MESSAGES.CURRENT_CUSTOMER}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 500,
                            fontSize: isSmallScreen ? "14px" : "16px",
                          }}
                        >
                          {tableData.length} {TEXT_MESSAGES.AVAILABLE_SERVICE}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                          width: "100%",
                          maxWidth: isLargeScreen ? "450px" : "100%",
                          minHeight: isMediumScreen ? "50vh" : "60vh",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            mb: 2,
                            fontSize: isSmallScreen ? "14px" : "16px",
                          }}
                        >
                          {TEXT_MESSAGES.NO_QUOTATION}
                        </Typography>
                        <PheonixButton
                          label={TEXT_MESSAGES.ADD_QUOTATION}
                          width={"auto"}
                          endIcon={
                            <OpenInNewIcon sx={{ color: "white", width: 23 }} />
                          }
                          variant="contained"
                          onClick={handleAddQuotation}
                          sx={{
                            background: themestyle.colors.Primary2,
                            color: themestyle.colors.white,
                            textTransform: "none",
                            marginBottom: 2,
                            borderRadius: "16px",
                            width: isSmallScreen ? "90%" : "auto",
                            "&:hover": {
                              background: themestyle.colors.Primary2,
                              color: themestyle.colors.white,
                            },
                          }}
                        />
                        <Box
                          component="img"
                          src={product}
                          alt="Product"
                          sx={{
                            width: isSmallScreen
                              ? "120px"
                              : isMediumScreen
                              ? "140px"
                              : "150px",
                            height: isSmallScreen
                              ? "130px"
                              : isMediumScreen
                              ? "150px"
                              : "161px",
                            maxWidth: "100%",
                          }}
                        />
                      </Box>
                    </Box>
                  }
                />
              </div>
            ) : (
              <div style={{ width: "100%", overflowX: "auto" }}>
                <PheonixDataGrid
                  columns={columns}
                  pageSizeOptions={[5, 10, 20, 30]}
                  style={{ border: "0px", marginLeft: "20px" }}
                  rows={tableData}
                  isShownAlert={false}
                  searchQuery={searchQuery}
                  onSearchQueryChange={handleSearchQueryChange}
                  loading={dataLoading}
                  rowCount={tableCount}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  showPagination={currentView === "list"}
                  onPaginationModelChange={handlePaginationChange}
                  getRowId={(row) =>
                    row.id || `${paginationModel.page}-${row.serial_no}`
                  }
                  sx={{
                    "& .MuiButtonBase-root.MuiButton-root": {
                      color: "#49955E",
                    },
                    width: "auto",
                  }}
                />
              </div>
            )}
          </PheonixPaper>
        )}
        <PheonixPopup
          open={popup}
          onClose={handlePopupClose}
          onConfirm={() => setPopup(false)}
        />
        <EditConfirmationDialog
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          productName={selectedQuotation?.quotation_code || ""}
          onConfirm={handleEditConfirmation}
          selectedProduct={selectedQuotation}
          entityName="Information"
        />
        <PheonixSnackbar
          vertical="top"
          horizontal="right"
          open={snackbarOpen}
          onClose={() => {
            setSnackbarOpen(false);
          }}
          message={alertMessage}
          severity="success"
          backgroundColor={
            snackbarOpen
              ? snackbarType === "add"
                ? "#2E7D32"
                : snackbarType === "edit"
                ? "#EDF7ED"
                : "#D32F2F29"
              : "transparent"
          }
          color={
            snackbarOpen
              ? snackbarTypeColor === "add"
                ? "#FFFFFF"
                : snackbarTypeColor === "edit"
                ? "#1E4620"
                : "#663C00"
              : "transparent"
          }
          sx={{
            fontFamily: "Lexend",
            top: "170px",
            fontWeight: 500,
            fontSize: isSmallScreen ? "12px" : isMediumScreen ? "13px" : "14px",
            mt: isSmallScreen ? 15 : isMediumScreen ? 12 : 12,
            height: isSmallScreen ? "30px" : isMediumScreen ? "32px" : "35px",
            paddingTop: isSmallScreen ? "2px" : "3px",
            paddingBottom: isSmallScreen ? "4px" : "6px",
            width: "auto", 
            maxWidth: isSmallScreen ? "90%" : isMediumScreen ? "400px" : "500px", 
            minWidth: "200px", 
            whiteSpace: "nowrap", 
            paddingLeft: "10px", 
            paddingRight: "10px", 
          }}
        />
      </Box>
    </div>
  );
};
export default Quotation;
