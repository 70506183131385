import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShopIcon from '@mui/icons-material/Shop';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import GroupIcon from '@mui/icons-material/Group';
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AssessmentIcon from '@mui/icons-material/Assessment';
import TuneIcon from '@mui/icons-material/Tune';
import LockIcon from '@mui/icons-material/Lock';
import SegmentIcon from '@mui/icons-material/Segment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Tooltip from "@mui/material/Tooltip";
import logo from "../assets/translogo.png";
import profile from "../assets/profile.png"
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import SidebarItem from "../Components/PheonixSideBar";
import SidebarProps from "../interfaces/SideBarProps";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
interface MenuItemType {
  icon: React.ReactNode;
  text: string;
  to: string;
  expandIcon?: React.ReactNode;
  expandedContent?: Array<{ icon: React.ReactNode; text: string; to: string }>;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});


  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      onToggle();
    }
  };
  const toggleExpand = (itemText: any) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [itemText]: !prevState[itemText],
    }));
  };

  const handleProfile = () => {
    navigate('/profile')
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (to: string) => {
    navigate(to);
    handleMenuClose();
  };

  const iconComponent = isOpen ? (
    <ChevronLeftIcon style={{ width: "28px", height: "28px", color: 'black' }} />
  ) : (
    <MenuIcon style={{ color: "black" }} />
  );

  const menuItems = [
    {
      icon: <DashboardIcon />,
      text: "Dashboard",
      to: "/home",
    },
    {
      icon: <RequestQuoteOutlinedIcon />,
      text: "Quotation",
      to: "/quotation",
    },
    {
      icon: <ReceiptOutlinedIcon />,
      text: "Invoice",
      to: '',
      expandIcon: <ArrowDropDownIcon />,
      expandedContent: [
        {
          icon: <ShopIcon />,
          text: "Purchase",
          to: "/purchase",
        },
        {
          icon: <RequestQuoteOutlinedIcon />,
          text: "Sales",
          to: "/sales",
        },
      ],
    },
    {
      icon: <AssessmentIcon />,
      text: "Reports",
      to: '',
      expandIcon: <ArrowDropDownIcon />,
      expandedContent: [
        {
          icon: <SegmentIcon />,
          text: "Stock",
          to: "/stock",
        },
        {
          icon: <ShoppingCartIcon />,
          text: "Purchase",
          to: "/purchasereports",
        },
        {
          icon: <RequestQuoteOutlinedIcon />,
          text: "Sales",
          to: "/salesreport",
        },
      ],
    },
    {
      icon: <PeopleIcon />,
      text: "Parties",
      to: '',
      expandIcon: <ArrowDropDownIcon />,
      expandedContent: [
        {
          icon: <ConnectWithoutContactIcon />,
          text: "Supplier",
          to: "/supplier",
        },
        {
          icon: <AccountCircleIcon />,
          text: "Customer",
          to: "/customer",
        },
      ],
    },
    {
      icon: <SettingsInputComponentIcon />,
      text: "Management",
      to: '',
      expandIcon: <ArrowDropDownIcon />,
      expandedContent: [
        {
          icon: <ListAltOutlinedIcon />,
          text: "Product",
          to: "/product",
        },
        {
          icon: <SegmentIcon />,
          text: "Stock",
          to: "/stocks",
        },
        {
          icon: <GroupIcon />,
          text: "Staff",
          to: "/staff",
        },
      ],
    },
  ];


  const listItemStyle = {
    backgroundColor: "#FFFFFF",
  };

  const selectedItemStyle = {
    backgroundColor: "#D5E3FF",
  };

  useEffect(() => {
    if (isMobile && isOpen) {
      const menuIconElement = document.getElementById("menuIcon");
      if (menuIconElement) {
        setMenuAnchorEl(menuIconElement);
      }
    }
  }, [isMobile, isOpen]);

  return (
    <>
{isMobile ? (
  <>
    <div
      id="menuIcon"
      onClick={handleMenuClick}
      style={{
        position: "absolute",
        top: "15px",
        left: "15px",
        cursor: "pointer",
      }}
    >
      <MenuIcon style={{ color: "black" }} />
    </div>
    <Menu
      anchorEl={menuAnchorEl}
      open={Boolean(menuAnchorEl)}
      onClose={handleMenuClose}
    >
      {menuItems.map((item, index) => (
        <div key={index}>
          <MenuItem
            onClick={() =>
              item.expandIcon ? toggleExpand(item.text) : handleMenuItemClick(item.to)
            }
            sx={item.to === location.pathname ? selectedItemStyle : listItemStyle}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                color: "#212121",
                fontWeight: 700,
              }}
            >
              {item.icon}
              <Typography style={{ color: "#212121", fontWeight: 700 }}>
                {item.text}
              </Typography>
              {item.expandIcon &&
                (expandedItems[item.text] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
            </div>
          </MenuItem>
          {expandedItems[item.text] && item.expandedContent && (
            item.expandedContent.map((subItem, subIndex) => (
              <MenuItem
                key={subIndex}
                onClick={() => handleMenuItemClick(subItem.to)}
                sx={
                  subItem.to === location.pathname ? selectedItemStyle : listItemStyle
                }
                style={{ paddingLeft: "40px" }} 
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    color: "#212121",
                    fontWeight: 500,
                  }}
                >
                  {subItem.icon}
                  <Typography style={{ color: "#212121", fontWeight: 500 }}>
                    {subItem.text}
                  </Typography>
                </div>
              </MenuItem>
            ))
          )}
        </div>
      ))}
    </Menu>
  </>
) : (
      <Drawer
        variant="permanent"
        sx={{
          width: isOpen ? 225 : 65,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            background: "linear-gradient(194.32deg, #85E956 2.15%, #30BBC4 85.53%)",
            fontSize: "16px",
            color: "#FFFFFFB3",
            width: isOpen ? 232 : 65,
            boxSizing: "border-box",
            transition: "width ease-in-out, visibility 0.2s linear",
            overflow: "hidden",
            border: "none",
            
          },
        }}
        open={isOpen}
        onClose={onToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div
          onClick={handleMenuClick}
          style={
            isOpen
              ? {
                  position: "absolute",
                  top: "20px",
                  right: "0px",
                  left: "40px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }
                : {
                  position: "absolute",
                  top: "20px",
                  left: "25px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }
            }
          >
            {isOpen && <img src={logo} alt="Logo" style={{ top: "28px", height: '34px', width: "189px", marginRight: '20px' }} />}
            {iconComponent}
          </div>

          <List sx={{ top: "65px", left: '10px' }}>
            {menuItems.map((item, index) => (
              <div key={index}>
                <Tooltip
                  title={isOpen ? '' : item.text}
                  placement="bottom"
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -15],
                        },
                      },
                    ],
                  }}
                >
                  <div onClick={() => item.expandIcon && toggleExpand(item.text)}>
                    <SidebarItem
                      icon={item.icon}
                      text={item.text}
                      to={item.to}
                      isOpen={isOpen}
                      expandIcon={item.expandIcon ? (expandedItems[item.text] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : null}
                    />
                  </div>
                </Tooltip>
                {expandedItems[item.text] && item.expandedContent && (
                  <div className="expanded-content">
                    {item.expandedContent.map((subItem, subIndex) => (
                      <Tooltip
                        key={subIndex}
                        title={isOpen ? '' : subItem.text}
                        placement="bottom"
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        }}
                      >
                        <div>
                          <SidebarItem
                            icon={subItem.icon}
                            text={subItem.text}
                            to={subItem.to}
                            isOpen={isOpen}
                          />
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </List>
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
