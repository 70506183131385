import React from "react";
import { useState, useEffect } from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixTextField from "../Components/PheonixTextField";

interface PriceDetailsProps {
  data: Record<string, any>;
  onSave: (data: Record<string, any>) => void;
  error?: string | null;
  readOnly?: boolean;
  fieldErrors: Record<string, string>;
  setFieldErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}
const PriceDetails: React.FC<PriceDetailsProps> = ({
  data,
  onSave,
  error,
  readOnly,
  fieldErrors,
  setFieldErrors,
}) => {
  const [isTaxable, setIsTaxable] = useState(true);
  const [gst, setGST] = useState<string | null>(null);
  const [unit, setUnit] = useState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [formData, setFormData] = useState(data);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

  const commonStyles = {
    textField: {
      width: "100%",
      maxWidth: "348px",
      height: "48px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root": {
        color: "gray",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "gray",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "10px",
        fontFamily: "Lexend",
        margin: 0,
        Padding: 0,
      },
    },
    autoComplete: {
      width: "100%",
      maxWidth: "348px",
      "& .MuiAutocomplete-root": {
        color: "#00000061",
      },
    },
  };

  const gstOptions = [
    { value: "0%", label: "0%" },
    { value: "5%", label: "5%" },
    { value: "12%", label: "12%" },
    { value: "18%", label: "18%" },
    { value: "28%", label: "28%" },
  ];

  const unitOptions = [
    { value: "Bags", label: "Bags" },
    { value: "Kilogram", label: "Kilogram" },
    { value: "Gram", label: "Gram" },
    { value: "Centimeter", label: "Centimeter" },
  ];

  const handleSwitchChange = () => {
    setIsTaxable(!isTaxable);
  };

  const handleGSTpercentage = (_: React.SyntheticEvent, newValue: any) => {
    setGST(newValue?.value || "");
  };

  const handleUnitChange = (_: React.SyntheticEvent, newValue: any) => {
    setUnit(newValue?.value || "");
  };
  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleInputChange = (field: string, value: any) => {
    let parsedValue = value;

    const floatFields = [
      "purchase_price",
      "purchase_price_gst",
      "sales_price",
      "sales_rate",
      "sales_price_gst",
      "purchase_rate",
    ];

    if (floatFields.includes(field)) {
      parsedValue = value === "" ? "" : parseFloat(value) || 0;
    }
    const updatedData = { ...formData, [field]: parsedValue };
    setFormData(updatedData);

    onSave(updatedData);
  };
  const validateField = (field: string, value: any) => {
    let error = "";
    if (
      [
        "purchase_price",
        "purchase_rate",
        "purchase_unit",
        "purchase_gst",
        "purchase_price_gst",
        "sales_price",
        "sales_rate",
        "sales_unit",
        "sales_gst",
        "sales_price_gst",
      ].includes(field)
    ) {
      if (!value) {
        error = `${field.replace("_", " ")} is required`;
      }
    }

    return error;
  };

  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const error = validateField(field, formData[field]);
    setFieldErrors((prev) => ({ ...prev, [field]: error }));
  };

  const handleSave = () => {
    onSave(formData);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen
          ? "column"
          : isMediumScreen
          ? "column"
          : "row",
        gap: isSmallScreen ? "16px" : "24px",
        padding: isSmallScreen ? "16px" : "32px",
        height: "auto",
        overflow: "auto",
      }}
    >
      <Box
        sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "16px" }}
      >
        <PheonixTextField
          type="text"
          id="outlined-error"
          label="Purchase Price"
          variant="standard"
          disabled={readOnly}
          value={formData.purchase_price}
          error={!!fieldErrors.purchase_price}
          helperText={fieldErrors.purchase_price || ""}
          onChange={(e) => handleInputChange("purchase_price", e.target.value)}
          onBlur={() => handleBlur("purchase_price")}
          required
          sx={commonStyles.textField}
        />
        <PheonixTextField
          label="Rate Per Item"
          type="text"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.purchase_rate}
          error={!!fieldErrors.purchase_rate}
          helperText={fieldErrors.purchase_rate || ""}
          onChange={(e) => handleInputChange("purchase_rate", e.target.value)}
          onBlur={() => handleBlur("purchase_rate")}
          sx={commonStyles.textField}
        />

        <Autocomplete
          options={unitOptions}
          disabled={readOnly}
          getOptionLabel={(option) => option.label}
          value={unitOptions.find(
            (option) => option.value === formData.purchase_unit || null
          )}
          onChange={(_, newValue) =>
            handleInputChange("purchase_unit", newValue?.value || "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Purchase Unit"
              type="text"
              disabled={readOnly}
              required
              error={!!fieldErrors.purchase_unit}
              helperText={fieldErrors.purchase_unit || ""}
              onBlur={() => handleBlur("purchase_unit")}
              variant="standard"
              sx={commonStyles.textField}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ fontFamily: "Lexend" }}>
              {option.label}
            </li>
          )}
          sx={commonStyles.autoComplete}
        />
        <Autocomplete
          options={gstOptions}
          disabled={readOnly}
          getOptionLabel={(option) => option.label}
          value={
            gstOptions.find(
              (option) => option.value === formData.purchase_gst
            ) || null
          }
          onChange={(_, newValue) =>
            handleInputChange("purchase_gst", newValue?.value || "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={readOnly}
              required
              error={!!fieldErrors.purchase_gst}
              helperText={fieldErrors.purchase_gst || ""}
              onBlur={() => handleBlur("purchase_gst")}
              label="Purchase GST"
              type="text"
              variant="standard"
              sx={commonStyles.textField}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ fontFamily: "Lexend" }}>
              {option.label}
            </li>
          )}
          sx={commonStyles.autoComplete}
        />

        <PheonixTextField
          type="text"
          id="outlined-error"
          label="Product Purchase Price(with GST)"
          variant="standard"
          disabled={readOnly}
          value={formData.purchase_price_gst}
          error={!!fieldErrors.purchase_price_gst}
          helperText={fieldErrors.purchase_price_gst || ""}
          onBlur={() => handleBlur("purchase_price_gst")}
          onChange={(e) =>
            handleInputChange("purchase_price_gst", e.target.value)
          }
          required
          sx={commonStyles.textField}
        />
      </Box>

      <Box
        sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "16px" }}
      >
        <PheonixTextField
          type="text"
          id="outlined-error"
          label="Sales Price"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.sales_price}
          error={!!fieldErrors.sales_price}
          helperText={fieldErrors.sales_price || ""}
          onBlur={() => handleBlur("sales_price")}
          onChange={(e) => handleInputChange("sales_price", e.target.value)}
          sx={commonStyles.textField}
        />

        <PheonixTextField
          label="Rate Per Item"
          type="text"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.sales_rate}
          error={!!fieldErrors.sales_rate}
          helperText={fieldErrors.sales_rate || ""}
          onChange={(e) => handleInputChange("sales_rate", e.target.value)}
          onBlur={() => handleBlur("sales_rate")}
          sx={commonStyles.textField}
        />

        <Autocomplete
          options={unitOptions}
          disabled={readOnly}
          getOptionLabel={(option) => option.label}
          value={unitOptions.find(
            (option) => option.value === formData.sales_unit || null
          )}
          onChange={(_, newValue) =>
            handleInputChange("sales_unit", newValue?.value || "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={readOnly}
              label="Sales Unit"
              type="text"
              variant="standard"
              required
              error={!!fieldErrors.sales_unit}
              helperText={fieldErrors.sales_unit || ""}
              onBlur={() => handleBlur("sales_unit")}
              sx={commonStyles.textField}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ fontFamily: "Lexend" }}>
              {option.label}
            </li>
          )}
          sx={commonStyles.autoComplete}
        />
        <Autocomplete
          options={gstOptions}
          disabled={readOnly}
          getOptionLabel={(option) => option.label}
          value={
            gstOptions.find((option) => option.value === formData.sales_gst) ||
            null
          }
          onChange={(_, newValue) =>
            handleInputChange("sales_gst", newValue?.value || "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={readOnly}
              label="Sales GST"
              variant="standard"
              required
              error={!!fieldErrors.sales_gst}
              helperText={fieldErrors.sales_gst || ""}
              onBlur={() => handleBlur("sales_gst")}
              sx={commonStyles.textField}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ fontFamily: "Lexend" }}>
              {option.label}
            </li>
          )}
          sx={commonStyles.autoComplete}
        />
        <PheonixTextField
          type="text"
          id="outlined-error"
          label="Product Sales Price(with GST)"
          disabled={readOnly}
          variant="standard"
          value={formData.sales_price_gst}
          error={!!fieldErrors.sales_price_gst}
          helperText={fieldErrors.sales_price_gst || ""}
          onChange={(e) => handleInputChange("sales_price_gst", e.target.value)}
          onBlur={() => handleBlur("sales_price_gst")}
          required
          sx={commonStyles.textField}
        />
      </Box>
    </Box>
  );
};

export default PriceDetails;
