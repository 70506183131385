import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ModalFormData from "../interfaces/ModalFormDataProps";
import PheonixButton from "../Components/PheonixButton";
import { TEXT_MESSAGES } from "../const";

interface ViewQuotationProps {
  onClose: () => void;
  quotation: ModalFormData;
  onSave?: (data: any) => void;
}

const ViewQuotation: React.FC<ViewQuotationProps> = ({
  quotation,
  onClose,
  onSave,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        padding: { xs: "10px", sm: "20px" },
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        border: "1px solid rgba(224,224,224,1)",
        minHeight: "73vh",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          padding: "16px",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box>
          <Typography>
            <b>{TEXT_MESSAGES.QUOTATION_NUMBER}</b>{" "}
            {quotation.quotation_code || "N/A"}
          </Typography>
          <Typography>
            <b>{TEXT_MESSAGES.CUSTOMER_NAME}</b>{" "}
            {quotation.customer_name || "N/A"}
          </Typography>
          <Typography>
            <b>{TEXT_MESSAGES.QUOTATION_STATUS}</b> {quotation.status || "N/A"}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>{TEXT_MESSAGES.EXPIRY}</b> {quotation.expiry_date || "N/A"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 600, borderCollapse: "collapse" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              {[
                "#",
                "Product Name",
                "Description",
                "HSN Code",
                "Quantity",
                "Unit",
                "Rate Per Item",
                "Taxes",
                "Amount",
              ].map((heading) => (
                <TableCell
                  key={heading}
                  sx={{
                    textAlign: "center",
                    padding: "8px",
                    border: "1px solid #ddd",
                  }}
                >
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quotation.productdata.map((product, index) => (
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: "8px",
                    border: "1px solid #ddd",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {product.product_name}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {product.product_description}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {product.hsn_code}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {product.quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {product.unit}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  ₹{product.sales_rate}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {product.sales_price_gst}%
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  ₹{product.sales_price}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          padding: "16px",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography sx={{ color: "#00000061" }}>
            {TEXT_MESSAGES.CUSTOMER_NOTESLABEL}
          </Typography>
          <textarea
            value={quotation.customernotes}
            style={{
              border: "0.5px solid",
              borderRadius: "10px",
              width: "100%",
              height: "68px",
              fontFamily: "Lexend",
              fontSize: "16px",
            }}
          />
          <Typography sx={{ color: "#00000061" }}>
            {TEXT_MESSAGES.TERMS_AND_CONDITION}
          </Typography>
          <textarea
            value={quotation.termsandconditions}
            style={{
              border: "0.5px solid",
              borderRadius: "10px",
              width: "100%",
              height: "68px",
              fontFamily: "Lexend",
              fontSize: "16px",
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Table
            sx={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#f5f5f5",
                }}
              >
                {["CGST in %", "SGST in %", "Amount"].map((heading) => (
                  <TableCell
                    key={heading}
                    sx={{
                      textAlign: "center",
                      padding: isSmallScreen ? "4px" : "8px",
                    }}
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>
                  {quotation.cgst}%
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {quotation.sgst}%
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  ₹{quotation.grandTotal}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>

      <PheonixButton
        label={TEXT_MESSAGES.BACK_BUTTON}
        width="90px"
        backgroundColor="white"
        borderColor="black"
        border="1px solid"
        color="secondary"
        variant="outlined"
        onClick={onClose}
      />
    </Box>
  );
};

export default ViewQuotation;
