import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client';
import "./index.css";
import baseUrl from './api';
import App from "./App";
import Home from "./Pages/Home";
import Profile from "./Pages/Profile";
import reportWebVitals from "./reportWebVitals";
import Layout from "./Pages/Layout";
import Quotation from "./Pages/Quotation";
import Staff from "./Pages/Staff";
import Product from "./Pages/Product";
import Purchase from "./Pages/Purchase";
import PurchaseReport from "./Pages/PurchaseReport";
import Sales from "./Pages/Sales";
import SalesReport from "./Pages/SalesReport";
import Stock from "./Pages/Stock";
import StockManagement from "./Pages/StockManagement";
import Suppliers from "./Pages/Suppliers";
import Customers from "./Pages/Customers";
const client = new ApolloClient({
  uri:baseUrl, 
  cache: new InMemoryCache(),
});

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/home" element={<Layout title="Dashboard"><Home /></Layout>} />
      <Route path="/purchase" element={<Layout title="Purchase"><Purchase /></Layout>} />
      <Route path="/sales" element={<Layout title="Sales"><Sales /></Layout>} />
      <Route path="/quotation" element={<Layout title="Quotation"><Quotation /></Layout>} />
      <Route path="/stock" element={<Layout title="Stock"><Stock /></Layout>} />
      <Route path="/purchasereports" element={<Layout title="Purchase"><PurchaseReport /></Layout>} />
      <Route path="/salesreport" element={<Layout title="Sales"><SalesReport /></Layout>} />
      <Route path="/product" element={<Layout title="Product"><Product /></Layout>} />
      <Route path="/staff" element={<Layout title="Staff"><Staff /></Layout>} />
      <Route path="/profile" element={<Layout title="Profile"><Profile /></Layout>} />
      <Route path="/stocks" element={<Layout title="Stock"><StockManagement /></Layout>} />
      <Route path="/supplier" element={<Layout title="Supplier"><Suppliers /></Layout>} />
      <Route path="/customer" element={<Layout title="Customer"><Customers /></Layout>} />
    </Routes>
  );
};




const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
document.body.style.backgroundColor = "white";
root.render(
  <React.StrictMode>
  <ApolloProvider client={client}>
    <Router>
      <AppRouter />
    </Router>
  </ApolloProvider>
</React.StrictMode>
);

reportWebVitals();