import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup as MUIRadioGroup,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  GET_LAST_QUOTATION,
  CHECK_QUOTATION_EXISTS_QUERY,
} from "../graphql/query";
import { useQuery } from "@apollo/client";
import baseUrl from "../api";
import axios from "axios";

interface QuotationPreferencesDialogProps {
  open: boolean;
  onClose: () => void;
  isEditMode: boolean;
  onConfirm: (finalQuotationCode: string) => void;
}

export default function QuotationModel({
  open,
  onClose,
  isEditMode,
  onConfirm,
}: QuotationPreferencesDialogProps) {
  const [preference, setPreference] = useState<"auto" | "manual">("auto");
  const [quotationNumber, setQuotationNumber] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastQuotationCode, setLastQuotationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    data: quotation,
    loading: quotationLoading,
    refetch: quotationnumberrefetch,
  } = useQuery(GET_LAST_QUOTATION);

  useEffect(() => {
    if (quotation?.getLastQuotation?.quotation_code) {
      const lastQuotationCode = quotation.getLastQuotation.quotation_code;
      setLastQuotationCode(lastQuotationCode);
      setQuotationNumber(generateNextQuotationNumber(lastQuotationCode));
    }
  }, [quotation]);

  useEffect(() => {
    quotationnumberrefetch();
  }, []);

  const generateNextQuotationNumber = (lastQuotationCode: string) => {
    const lastNumber = parseInt(lastQuotationCode.replace("QTN-", ""), 10) || 0;
    const nextNumber = String(lastNumber + 1).padStart(6, "0");

    return `QTN-${nextNumber}`;
  };

  const handleValidateQTN = async () => {
    if (!quotationNumber.trim()) return;

    try {
      const response = await axios.post(baseUrl, {
        query: CHECK_QUOTATION_EXISTS_QUERY,
        variables: { quotation_code: quotationNumber.trim() },
      });

      const exists = response.data?.data?.checkQuotationExists;
      if (exists) {
        setErrorMessage(
          "Quotation Code already exists. Please enter a different one."
        );
        setIsValid(false);
      } else {
        setErrorMessage("");
        setIsValid(true);
      }
    } catch (error) {
      console.error("Error validating QTN:", error);
    }
  };

  const handleConfirm = async () => {
    let finalQuotationCode =
      preference === "auto"
        ? generateNextQuotationNumber(lastQuotationCode)
        : quotationNumber;

    if (preference === "manual" && !isValid) return;
    onConfirm(finalQuotationCode);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ maxWidth: "500px", p: 3 }}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Quotation Preferences
          </Typography>
        </Box>

        <Box sx={{ py: 2 }}>
          <MUIRadioGroup
            value={preference}
            onChange={(e) => setPreference(e.target.value as "auto" | "manual")}
          >
            <FormControlLabel
              value="auto"
              control={<Radio />}
              label="Auto-generate quotation number"
            />
            <FormControlLabel
              value="manual"
              control={<Radio />}
              label="Enter quotation number manually"
            />
          </MUIRadioGroup>

          {preference === "manual" && (
            <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: 1 }}>
              <TextField
                value={quotationNumber}
                onChange={(e) => setQuotationNumber(e.target.value)}
                onBlur={handleValidateQTN}
                size="small"
                sx={{ maxWidth: "220px" }}
                error={!isValid}
                helperText={validationError}
              />
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    bgcolor: isValid ? "#f0fdf4" : "#fef2f2",
                    color: isValid ? "#16a34a" : "#dc2626",
                  }}
                >
                  {isValid ? (
                    <CheckIcon sx={{ fontSize: 16 }} />
                  ) : (
                    <CancelIcon sx={{ fontSize: 16 }} />
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>

        <DialogActions sx={{ justifyContent: "flex-end", gap: 1 }}>
          <Button variant="outlined" onClick={onClose} sx={{ px: 3 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            sx={{
              px: 3,
              bgcolor: "#1f2937",
              "&:hover": { bgcolor: "#111827" },
            }}
            disabled={preference === "manual" && !isValid}
          >
            Confirm
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
