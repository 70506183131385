import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PopupModalProps from "../interfaces/PheonixPopupprops";
import { TEXT_MESSAGES } from "../const";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const PheonixPopup: React.FC<PopupModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#fff",
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
          position: "relative",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          height: "219px",
          width: "343px",
          padding: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection:"column",
          justifyContent: "center",
          alignItems: "center",
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: "18px",
          padding: "0",
          marginBottom: "8px",
          position: "relative",
        }}
      >
         <Box
            sx={{
              width: 40,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorOutlineIcon style={{width:"35px",height:"35px"}}/>

          </Box>
         
        
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 0.87)",
          letterSpacing: "0.15px",
          padding: "0",
          marginTop:"10px"
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: "14px", fontFamily:"Lexend"}}>
          Are you sure you want to leave this page? 
         </Typography>
         <Typography sx={{ fontWeight: 300, fontSize: "12px",fontFamily:"Lexend", marginTop:"5px"}}>
          All unsaved changes will be lost and cannot be recovered!
         </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          gap:"20px",
          position:"absolute",
          top:"60%",
          left:"10%",
          
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            px: 4,
            borderColor: "#152726",
            color: "black",
            "&:hover": {
              borderColor: "#152726",
              color: "black",
              opacity: 0.9,
            },
          }}
        >
         Leave
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          sx={{
          
            bgcolor: "black",
            "&:hover": {
              bgcolor: "black",
              opacity: 0.9,
            },
          }}
        >
          Stay on Page
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PheonixPopup;
