import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import PheonixSideBarProps from '../interfaces/PheonixSideBarProps';

const PheonixSideBar: React.FC<PheonixSideBarProps> = ({ icon, text, to, isOpen,expandIcon }) => {
  const location = useLocation();
return (
  <ListItem
  sx={{
    textDecoration: 'none',
    backgroundColor: location.pathname === to ? '#F2FCFF' : 'transparent',
    color: 'black',
    borderTopLeftRadius: '25px',
    borderBottomLeftRadius: '25px',
    borderRight: location.pathname === to ? '4px solid #54CE96' : 'none',
    '&:hover': { backgroundColor: '#F2FCFF', textDecoration: 'none' },
    fontFamily:"Lexend",
    fontSize: '18px',
    '& .MuiTypography-root': {
      fontSize: '18px', 
      fontWeight: '400',
      fontFamily:"Lexend",
    },
  }}
  component={Link}
  to={to}
>
  <ListItemIcon sx={{ color: location.pathname === to ? 'black' : 'inherit' }}>
    {icon}
  </ListItemIcon>
  <ListItemText primary={text} />
  {expandIcon && (
    <ListItemIcon sx={{ color: location.pathname === to ? 'black' : 'inherit' }}>
       <div style={{ marginLeft: '30px',marginTop:'4px'}}>
      {expandIcon}
      </div>
    </ListItemIcon>
  )}
</ListItem>
  );
};
export default PheonixSideBar;