import React, { useState, useEffect, } from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
} from "@mui/material";
import PheonixButton from "../Components/PheonixButton";
import { useQuery, } from "@apollo/client";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { GridColDef } from "@mui/x-data-grid";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import themestyle from "../theme";
import EditIcon1 from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PheonixPaper from "../Components/PheonixPaper";
import ModalFormData from "../interfaces/ModalFormDataProps";
import axios from "axios";
import baseUrl from "../api";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import { PheonixSnackbar } from "pheonixui";
import { useLocation } from 'react-router-dom';
import ListAltIcon from "@mui/icons-material/ListAlt";
import product from "../assets/product.png";
import { GET_CUSTOMER_QUERY, DELETE_CUSTOMER } from "../graphql/query";
import AddCustomer from './AddCustomers';
import PheonixPopup from "../Components/PheonixPopup";
import EditCustomer from './EditCustomer';
import CustomerView from "./Viewcustomer";
import EditConfirmationDialog from "./Editmodel";
import DeleteModal from "../Components/PheonixDeletemodel";


const Customer: React.FC = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const isMobile = useMediaQuery("(max-width:600px)");
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [dataLoading, setdataLoading] = useState(false);
  const [tableData, setTableData] = useState<ModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showCustomer, setShowCustomer] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<"add" | "edit" | "delete" | "">("");
  const [snackbarTypecolor, setSnackbarTypecolor] = useState<"add" | "edit" | "delete" | "">("");
  const [alertmessage, setalertmessage] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeStep, setActiveStep] = useState(0);
  const [searchInput, setSearchInput] = useState<string>("");
  const location = useLocation();
  const [currentView, setCurrentView] = useState<string>(location.state?.currentView || 'list' || 'addcustomers' || 'view' || "edit");
  const [breadcrumbText, setBreadcrumbText] = useState<string>(location.state?.breadcrumbText || '');
  const [popup, setPopup] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState<any | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");

  const handleFileChange = (newFiles: File[]) => {
    setFiles(newFiles);
  };
  const handleAddCustomer = () => {
    setCurrentView("add customer");
    setBreadcrumbText("Add Customer");
    setShowCustomer(true);
  };
  const handleBreadcrumbClick = (breadcrumbText: string) => {
    if (breadcrumbText === "customer" && (currentView !== "list")) {
      setPopup(true)
    }
  };
  const handlePopupclose = () => {
    setActiveStep(0);
    setCurrentView("list");
    setShowCustomer(false)
    setBreadcrumbText(breadcrumbText)
    setPopup(false)
  }
  const handleCustomerViewClose = () => {
    setCurrentView("list");
    setBreadcrumbText("Customer listing");
    setIsView(false);
    setSelectedCustomer(null);
  };
  const handleSnackbarOpen = (type: "add" | "edit" | "delete") => {
    setSnackbarType(type);
    setSnackbarTypecolor(type);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
      setSnackbarType("");
      setSnackbarTypecolor("");
    }, 3000);
  };
  const handleCancelEdit = () => {
    setActiveStep(0);
    setIsEditing(false);
    setCurrentView("list");
    setBreadcrumbText(breadcrumbText);
  };
  const handleEditClick = () => {
    if (selectedCustomer) {
      setIsEditDialogOpen(true);
    }
  };
  const handleEditConfirmation = (selectedRow: ModalFormData) => {
    setIsEditDialogOpen(false);
    setBreadcrumbText("Edit Customer");
    setSelectedCustomer(selectedRow);
    setCurrentView("edit");
    setIsEditing(true);
  };
  const handleView = (selectedRow: ModalFormData) => {
    setSelectedCustomer(selectedRow);
    setIsView(true)
    setBreadcrumbText("View Customer");
    setCurrentView("view");
  };
  const handleSaveEdit =  () => {
    setBreadcrumbText("Customer Listing");
    setCurrentView("list");
    setActiveStep(0);
    setSelectedCustomer(null);
    customerRefetch();

    setalertmessage(TEXT_MESSAGES.EDITED_CUSTOMER);
    handleSnackbarOpen("edit");
  };
  const handleOpenDialog = (customer: ModalFormData) => {
    setSelectedCustomer(customer);
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedCustomer(null);
  };
  const handleSave = (newCustomer: ModalFormData) => {
    const newCustomerWithId = {
      ...newCustomer,
      id: newCustomer.id || `temp-id-${Math.random()}`,
    };
    setTableData((prev) => [...prev, newCustomerWithId]);
    setShowCustomer(false);
    setCurrentView("list");
    setBreadcrumbText("Product Listing");
    customerRefetch();
    setalertmessage(TEXT_MESSAGES.CUSTOMER_ADDED);
    handleSnackbarOpen("add");
  };
  const handleDeleteClick = (id: string, customer: ModalFormData) => {
    setEntityToDelete(id);
    setSelectedCustomer(customer);
    setEntityToDisplay(customer.customer_name);
    setDeleteModalOpen(true);
    setIsView(false)
  };
  const handleDelete = async (customer: ModalFormData): Promise<void> => {
    if (!customer || !customer.id) {
      console.error(ERROR_MESSAGES.DELETE);
      return;
    }
    try {
      const response = await axios.post(baseUrl, {
        query: DELETE_CUSTOMER.loc?.source.body,
        variables: { id: customer.id },
      });

      const { statusCode, message } = response.data.data?.deleteCustomer;
      if (statusCode === 200) {
        setTableData((prev) => {
          const newData = prev.filter((p) => p.id !== customer.id);
          return newData;
        });
        setalertmessage(TEXT_MESSAGES.CUSTOMER_DELETE);
        handleSnackbarOpen("delete");
        setDeleteModalOpen(false);
        customerRefetch();
      } else {
        console.error(message || ERROR_MESSAGES.FAILED_TODELETE);
      }
    } catch (error: any) {
      console.error("Delete Error:", error);
    }
  };
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: customerRefetch,
  } = useQuery(GET_CUSTOMER_QUERY, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery,
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, customers } = data.getCustomersPerPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const customersWithSerialNo = customers.map(
        (customer: ModalFormData, index: number) => {
          return {
            ...customer,
            id: customer.id || `${paginationModel.page}-${startIndex + index}`,
            serial_no: startIndex + index + 1,
          };
        }
      );
      setTableData(customersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    },
  });
  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData?.getCustomersPerPage) {
      const { totalPages, totalCount, customers } = customerData.getCustomersPerPage;
      const customerArray = Array.isArray(customers) ? customers : [];
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const customersWithSerialNo = customerArray.map(
        (customer: ModalFormData, index: number) => {
          return {
            ...customer,
            id: customer.id || `${paginationModel.page}-${startIndex + index}`,
            serial_no: (startIndex + index + 1).toString(),
          };
        }
      );
      setTableData(customersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize, -
    searchQuery,
  ]);

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: 0, 
    }));
    if (newQuery.trim() === "") {
      customerRefetch({ variables: { searchQuery: {} } });
    } else {
      customerRefetch();
    }
  };
  const handlePaginationChange = (newModel: {
    page: number;
    pageSize: number;
  }) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: newModel.page,
      pageSize: newModel.pageSize,
    }));
  };
  useEffect(() => {
    setActiveStep(0);
    customerRefetch({
      variables: {
        page: paginationModel.page,
        perPage: paginationModel.pageSize,
        searchQuery: searchQuery.trim() === "" ? undefined : searchQuery,
      },
    });
  }, [searchQuery, paginationModel.page, paginationModel.pageSize]);

  const columns: GridColDef[] = [
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: isMobile ? 200 : 200,
      minWidth: 100,
      flex: isMobile ? 1 : 0,
    },
    {
      field: "phone_number",
      headerName: "Mobile Number",
      flex: 1,
      minWidth: isMobile ? 150 : 120,
    },
    {
      field: "gst",
      headerName: "GSTIN",
      flex: 1,
      minWidth: isMobile ? 150 : 120,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: isMobile ? 150 : 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: isMobile ? 100 : 100,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <IconButton onClick={() => handleView(params.row)} size="small">
            <RemoveRedEyeSharpIcon style={{ width: "18px", height: "18px" }} />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.row.id, params.row)} size="small">
            <DeleteIcon style={{ width: "18px", height: "18px" }} />
          </IconButton>
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: isMobileSmall ? "15px 25px" : "15px 30px",
            marginLeft: "-12px",
            fontFamily: "Lexend",
          }}
        >
          <Typography
            style={{
              fontFamily: "Lexend",
              color: themestyle.colors.black,
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
            }}
          >
            {TEXT_MESSAGES.CUSTOMER}
          </Typography>
          <PheonixBreadcrumbs breadcrumbText={breadcrumbText} onBreadcrumbClick={handleBreadcrumbClick} />
        </div>
        {currentView === "view" && (
          <PheonixButton
            label={isSmallScreen ? "" : TEXT_MESSAGES.EDIT_BUTTON}
            width={isSmallScreen ? "43px" : "90px"}
            startIcon={<EditIcon1 />}
            sx={{
              background: themestyle.colors.Primary2,
              color: themestyle.colors.white,
              minWidth: isSmallScreen ? "30px" : "90px",
              marginRight: "20px",
              marginTop: "10px",
              transform: "none",
              textTransform: "none",
            }}
            onClick={handleEditClick}
          ></PheonixButton>
        )}

        {currentView === "list" && tableData.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: isSmallScreen ? "85px" : isMediumScreen ? "340px" : "401px",
                height: isSmallScreen ? "32px" : "43px",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                flexWrap: "nowrap",
                gap: isSmallScreen ? "13px" : isMediumScreen ? "15px" : "32px",
                padding: isMediumScreen ? "16px" : "0",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <PheonixButton
                label={
                  isSmallScreen ? (
                    <ListAltIcon style={{ width: "24px", height: "24px" }} />
                  ) : (
                    TEXT_MESSAGES.ADD_CUSTOMER
                  )
                }
                style={{
                  background: themestyle.colors.Primary2,
                  color: themestyle.colors.white,
                  width: isSmallScreen ? "auto" : "153px",
                  minWidth: isSmallScreen ? "40px" : "150px",
                  height: "43px",
                  fontWeight: 500,
                  fontSize: "16px",
                  transform: "none",
                  textTransform: "none",
                  padding: isSmallScreen ? "5px" : "10px",
                  marginRight: "10px"


                }}
                onClick={handleAddCustomer}
              ></PheonixButton>

            </div>
          </div>
        )}
      </div>
      <Box sx={{ padding: "15px" }}>
        {showCustomer ? (

          <AddCustomer
            onClose={() => setShowCustomer(false)}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            onSave={handleSave}
          />
        )

          : currentView === "edit" && selectedCustomer ? (
            <EditCustomer
              customer={selectedCustomer}
              onSave={handleSaveEdit}
              onCancel={() => {
                setCurrentView("list");
                setSelectedCustomer(null);
                setIsEditing(false);
              }}
              onFileChange={(files) => console.error(files)}
            />
          ) : isView && selectedCustomer ? (
            <CustomerView
              customer={selectedCustomer}
              onClose={handleCustomerViewClose}
              onSave={(updatedCustomer: ModalFormData) => {
                setCurrentView("view");
                customerRefetch();
              }}
            />
          )
            : (
              <PheonixPaper>
                {tableData.length === 0 ? (
                  <div style={{ width: "80%", position: "fixed" }}>
                    <PheonixDataGrid
                      columns={[]}
                      rows={[]}
                      searchQuery={searchQuery}
                      isShownAlert={false}
                      onSearchQueryChange={handleSearchQueryChange}
                      rowCount={tableData.length}
                      showPagination={tableData.length > 0}
                      sx={{
                        "& .MuiDataGrid-footerContainer": {
                          visibility: "hidden",
                        },
                        "& .MuiButtonBase-root.MuiButton-root": {
                          color: "#49955E",
                        },
                      }}
                      customEmptyContent={
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              mb: 2,
                              textAlign: isSmallScreen ? "center" : "left",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: isSmallScreen ? "14px" : "16px",
                              }}
                            >
                              {TEXT_MESSAGES.CURRENT_CUSTOMER}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 500,
                                fontSize: isSmallScreen ? "14px" : "16px",
                              }}
                            >
                              {tableData.length} {TEXT_MESSAGES.AVAILABLE_SERVICE}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              textAlign: "center",
                              width: "100%",
                              maxWidth: isLargeScreen ? "450px" : "100%",
                              minHeight: isMediumScreen ? "50vh" : "60vh",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                mb: 2,
                                fontSize: isSmallScreen ? "14px" : "16px",
                              }}
                            >
                              {TEXT_MESSAGES.NO_CUSTOMER}
                            </Typography>
                            <PheonixButton
                              label={TEXT_MESSAGES.ADD_CUSTOMER}
                              width={"auto"}
                              endIcon={
                                <OpenInNewIcon sx={{ color: "white", width: 23 }} />
                              }
                              variant="contained"
                              onClick={handleAddCustomer}
                              sx={{
                                background: themestyle.colors.Primary2,
                                color: themestyle.colors.white,
                                textTransform: "none",
                                marginBottom: 2,
                                borderRadius: "16px",
                                width: isSmallScreen ? "90%" : "auto",
                                "&:hover": {
                                  background: themestyle.colors.Primary2,
                                  color: themestyle.colors.white,
                                },
                              }}
                            ></PheonixButton>
                            <br></br>
                            <Box
                              component="img"
                              src={product}
                              alt="Product"
                              sx={{
                                width: isSmallScreen
                                  ? "120px"
                                  : isMediumScreen
                                    ? "140px"
                                    : "150px",
                                height: isSmallScreen
                                  ? "130px"
                                  : isMediumScreen
                                    ? "150px"
                                    : "161px",
                                maxWidth: "100%",
                              }}
                            />
                          </Box>
                        </Box>
                      }
                    />
                  </div>
                ) : (
                  <div style={{ width: "100%", overflowX: "auto" }}>
                    <div></div>
                    <PheonixDataGrid
                      columns={columns}
                      pageSizeOptions={[5, 10, 20, 30]}
                      style={{ border: "0px", marginLeft: "20px" }}
                      rows={tableData}
                      isShownAlert={false}
                      searchQuery={searchQuery}
                      onSearchQueryChange={handleSearchQueryChange}
                      loading={dataLoading}
                      rowCount={tableCount}
                      paginationMode="server"
                      paginationModel={paginationModel}
                      showPagination={currentView === "list"}
                      onPaginationModelChange={handlePaginationChange}
                      getRowId={(row) =>
                        row.id || `${paginationModel.page}-${row.serial_no}`
                      }
                      sx={{
                        "& .MuiButtonBase-root.MuiButton-root": {
                          color: "#49955E",
                        },
                        width: "auto",
                      }}
                    />
                  </div>
                )}
              </PheonixPaper>
            )}
        <PheonixPopup
          open={popup}
          onClose={handlePopupclose}
          onConfirm={() => { setPopup(false) }}
        />
        <EditConfirmationDialog
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          productName={selectedCustomer?.customer_name || ""}
          onConfirm={handleEditConfirmation}
          selectedProduct={selectedCustomer}
          entityName={TEXT_MESSAGES.CUSTOMER_MODEL}
        />
        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={() => handleDelete(selectedCustomer)}
          entity={entityToDisplay} />
        <PheonixSnackbar
          vertical="top"
          horizontal="right"
          open={snackbarOpen}
          onClose={() => {
            setSnackbarOpen(false);
          }}
          message={alertmessage}
          severity="success"
          backgroundColor={
            snackbarOpen
              ? snackbarType === "add"
                ? "#2E7D32"
                : snackbarType === "edit"
                  ? "#EDF7ED"
                  : "#D32F2F29"
              : "transparent"
          }
          color={
            snackbarOpen
              ? snackbarTypecolor === "add"
                ? "#FFFFFF"
                : snackbarTypecolor === "edit"
                  ? "#1E4620"
                  : "#663C00"
              : "transparent"
          }
          sx={{
            fontFamily: "Lexend",
            top: "170px",
            fontWeight: 500,
            fontSize: isSmallScreen ? "12px" : isMediumScreen ? "13px" : "14px",
            mt: isSmallScreen ? 15 : isMediumScreen ? 12 : 12,
            height: isSmallScreen ? "30px" : isMediumScreen ? "32px" : "35px",
            paddingTop: isSmallScreen ? "2px" : "3px",
            paddingBottom: isSmallScreen ? "4px" : "6px",
            width: isSmallScreen ? "80%" : isMediumScreen ? "85%" : "auto",
            maxWidth: isSmallScreen ? "200px" : isMediumScreen ? "220px" : "250px",
            minWidth: "130px",
            whiteSpace: "nowrap",
          }}
        />
      </Box>
    </div>
  );
};

export default Customer;