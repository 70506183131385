import React, { useState, useEffect } from "react";
import { Box, Typography, Switch, Chip } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixTextField from "../Components/PheonixTextField";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from "@mui/icons-material/Close";
import { TEXT_MESSAGES,ERROR_MESSAGES } from "../const";

interface StockDetailsProps {
  data: Record<string, any>;
  onSave: (data: Record<string, any>) => void;
  error?: string | null;
  readOnly?: boolean;
  onFileChange?: (files: File[]) => void;
  fieldErrors: Record<string, string>;
  setFieldErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

const StockDetails: React.FC<StockDetailsProps> = ({
  data,
  onSave,
  error,
  readOnly = false,
  fieldErrors,
  onFileChange,
  setFieldErrors,
}) => {
  const [isalert, setIsalert] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [formData, setFormData] = useState(data);
  const [files, setFiles] = useState<File[]>([]);
  const [base64Files, setBase64Files] = useState<string[]>(data.stock_reference || []);

  const commonStyles = {
    textField: {
      width: "100%",
      maxWidth: "348px",
      height: "48px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent",
      },
      "& .MuiInputLabel-root": {
        color: "gray",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "gray",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "10px",
        fontFamily: "Lexend",
        margin: 0,
        padding: 0,
      },
    },
  };

  useEffect(() => {
    setFormData(data);
    setBase64Files(data.stock_reference || []);
    setFiles([]);
  }, [data]);

  const validateField = (field: string, value: any) => {
    let error = ""
    if (field === "open_stock") {
      if (value === "") {
        error = `${field.replace("_", " ")} is required`
      }
    }
    return error
  }
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleUpload(e.target.files);
      e.target.value = "";
    }
  };

  const handleUpload = (uploadedFiles: FileList) => {
    const newFiles = Array.from(uploadedFiles).filter((file) => file.type === "application/pdf");
    if (newFiles.length === 0) return;

    setFiles((prev) => {
      const updatedFiles = [...prev, ...newFiles];
      if (onFileChange) {
        onFileChange(updatedFiles);
      }
      return updatedFiles;
    });

    setFormData((prev) => {
      const updatedData = { ...prev, stock_reference: [...base64Files, ...newFiles] };
      onSave(updatedData);
      return updatedData;
    });
  };

  const removeFile = (index: number) => {
    if (index < base64Files.length) {

      const updatedBase64Files = base64Files.filter((_, i) => i !== index);
      setBase64Files(updatedBase64Files);
      const updatedData = { ...formData, stock_reference: [...updatedBase64Files, ...files] };
      setFormData(updatedData);
      onSave(updatedData);
      if (onFileChange) {
        onFileChange(files);
      }
    } else {
      const newIndex = index - base64Files.length;
      const updatedFiles = files.filter((_, i) => i !== newIndex);
      setFiles(updatedFiles);
      const updatedData = { ...formData, stock_reference: [...base64Files, ...updatedFiles] };
      setFormData(updatedData);
      onSave(updatedData);
      if (onFileChange) {
        onFileChange(updatedFiles);
      }
    }
  };
  const handleInputChange = (field: string, value: any) => {
    let parsedValue: any = value;
    if (["open_stock", "stock_alert"].includes(field)) {
      parsedValue = value === "" ? "" : Number(value);
    }
    const updatedData = { ...formData, [field]: parsedValue };
    setFormData(updatedData);
    onSave(updatedData);
  };

  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const error = validateField(field, formData[field]);
    setFieldErrors((prev) => ({ ...prev, [field]: error }));
  };

  const handleSwitchChange = () => {
    setIsalert(!isalert);
  };

  const downloadFile = (base64String: string, fileName: string) => {
    try {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName || TEXT_MESSAGES.DOWNLOAD_PDF;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(ERROR_MESSAGES.DOWNLOAD_FILE, err);
    }
  };
  const allFiles = [...base64Files, ...files];

  const handleSave = () => {
    const errors: { [key: string]: string } = {}
    Object.keys(formData).forEach((field) => {
      errors[field] = validateField(field, formData[field])
    })
    setFieldErrors(errors)
    setTouched(Object.keys(formData).reduce((acc, field) => ({ ...acc, [field]: true }), {}))
    if (Object.values(errors).every((error) => error === "")) {
      onSave(formData)
    }
  }


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : isMediumScreen ? "column" : "row",
        gap: isSmallScreen ? "16px" : "24px",
        padding: isSmallScreen ? "16px" : "32px",
        height: "auto",
        overflow: "auto",
      }}
    >
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: { xs: "12px", sm: "16px" } }}>
        <PheonixTextField
          type="text"
          label="Opening Stock"
          disabled={readOnly}
          variant="standard"
          value={formData.open_stock}
          error={Boolean(fieldErrors.open_stock)}
          helperText={fieldErrors.open_stock}
          onBlur={() => handleBlur("open_stock")}
          onChange={(e) => handleInputChange("open_stock", e.target.value)}
          required
          sx={commonStyles.textField}
        />
        <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "100%", sm: "348px" } }}>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
            <Typography sx={{ color: "gray", fontSize: { xs: "12px", sm: "14px" }, fontFamily: "Lexend" }}>
              {TEXT_MESSAGES.STOCK_ALERT_COUNT}
            </Typography>
            <Switch
              checked={readOnly ? formData.stock_alert > 0 : isalert}
              disabled={readOnly}
              onChange={handleSwitchChange}
              size="small"
              sx={{
                width: "40px",
                height: "20px",
                padding: "0",
                "& .MuiSwitch-thumb": { width: "16px", height: "16px", backgroundColor: "black", boxShadow: "none" },
                "& .MuiSwitch-switchBase": {
                  padding: "2px",
                  "&.Mui-checked": {
                    transform: "translateX(20px)",
                    "& .MuiSwitch-thumb": { backgroundColor: "black" },
                  },
                },
                "& .MuiSwitch-track": {
                  borderRadius: "10px",
                  backgroundColor: "gray",
                  width: "26px",
                  height: "10px",
                  margin: "5px",
                  transition: "background-color 0.2s",
                  "&.Mui-checked": { backgroundColor: "black" },
                },
              }}
            />
          </Box>
          <PheonixTextField
            type="text"
            variant="standard"
            disabled={readOnly}
            value={formData.stock_alert || ""}
            onChange={(e) => handleInputChange("stock_alert", e.target.value)}
            sx={{
              width: "100%",
              visibility: readOnly || isalert ? "visible" : "hidden",
              opacity: readOnly || isalert ? 1 : 0,
              transition: "opacity 0.2s linear",
              "& .MuiInputBase-root": { backgroundColor: "transparent" },
              "& .MuiInputLabel-root": { color: "#00000061", fontFamily: "Lexend" },
              "& .MuiInput-underline:before": { borderBottomColor: "gray" },
              "& .MuiInput-underline:after": { borderBottomColor: "black" },
            }}
          />
        </Box>
      </Box>

      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "16px" }}>
        <PheonixTextField
          type="text"
          label="Stock Notes"
          variant="standard"
          disabled={readOnly}
          value={formData.stock_notes || ""}
          onChange={(e) => handleInputChange("stock_notes", e.target.value)}
          sx={commonStyles.textField}
        />

        <PheonixTextField
          label="Stock Reference"
          type="text"
          disabled={readOnly}
          variant="standard"
          value={
            allFiles.length > 0
              ? allFiles
                .map((item, index) =>
                  item instanceof File ? item.name : `file_${index + 1}.pdf`
                )
                .join(", ")
              : ""
          }
          InputProps={{
            endAdornment: !readOnly && (
              <AttachFileIcon
                sx={{ cursor: "pointer", color: "gray", "&:hover": { color: "black" } }}
                onClick={() => document.getElementById("file-upload")?.click()}
              />
            ),
          }}
          sx={commonStyles.textField}
        />

        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
          accept=".pdf"
          multiple
          onChange={handleFileChange}
          disabled={readOnly}
        />

        {allFiles.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1 }}>
            {allFiles.map((item, index) => {
              const fileName =
                item instanceof File ? item.name : `filename_${index + 1}.pdf`;
              const isBase64File = index < base64Files.length;
              return (
                <Chip
                  key={index}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                      <Typography sx={{ color: isBase64File ? "black" : "black" }}>
                        {fileName}
                      </Typography>
                      {isBase64File && (
                        <FileDownloadOutlinedIcon
                          sx={{ fontSize: "16px", color: "black" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadFile(base64Files[index], fileName);
                          }}
                        />
                      )}
                    </Box>
                  }
                  onDelete={!readOnly ? () => removeFile(index) : undefined}
                  deleteIcon={
                    !readOnly ? (
                      <CloseIcon sx={{ fontSize: "5px", color: "gray" }} />
                    ) : undefined
                  }
                  sx={{
                    backgroundColor: "#e0e0e0",
                    "&:hover": { backgroundColor: "#d0d0d0" },
                  }}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StockDetails;
